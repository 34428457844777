import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Input,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import SearchOption from "../../Components/Common/SearchOption";
import ModalNovo from "./ModalNovo";
import Select from "react-select";
import ModalEditar from "./ModalEditar";
import ModalMudarStatus from "./ModalMudarStatus";
import ModalApagar from "./ModalApagar";

import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
  converterData,
} from "../../Components/FunctionsRepository";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const OrdensServico = () => {
  document.title = `Ordens de Serviço | ${process.env.REACT_APP_TITLE}`;

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [ordensServico, setOrdensServico] = useState([]);
  const [ordemServico, setOrdemServico] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeOrdensServico, setTotalDeOrdensServico] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [statusOrdem, setStatusOrdem] = useState("todos");
  const [clientePerfil, setClientePerfil] = useState("todos");

  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [filial, setFilial] = useState({});
  const [optionsFormasPagamento, setOptionsFormasPagamento] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState({});
  const [optionsExpedientes, setOptionsExpedientes] = useState([]);
  const [expediente, setExpediente] = useState({});
  const [optionsServicos, setOptionsServicos] = useState([]);

  const [modalNovo, setModalNovo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalMudarStatus, setModalMudarStatus] = useState(false);

  const arrowNavToggle = (tab) => {
    if (clientePerfil !== tab) {
      setClientePerfil(tab);
      setPaginaAtual(1);
      setLoadTable(true);
      setLoading(true);
    }
  };

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalEditar = useCallback(
    (seg) => {
      setOrdemServico(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalApagar = useCallback(
    (seg) => {
      setOrdemServico(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const toggleModalMudarStatus = useCallback(
    (seg) => {
      setOrdemServico(seg);
      setModalMudarStatus(!modalMudarStatus);
    },
    [modalMudarStatus]
  );

  const fetchServicos = useCallback(async () => {
    const response = await apiClient.get("/servicos");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsServicos(
        dados.map((servico) => ({
          label: `${servico?.servico} (${formatCurrency(servico?.preco)})`,
          nome: `${servico?.servico}`,
          preco: `${servico?.preco}`,
          value: `${servico?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchOrdensServico = useCallback(
    async (
      itensPorPagina,
      paginaAtual,
      termoBusca,
      statusOrdem,
      formaPagamento,
      expediente,
      perfil,
      filial
    ) => {
      const userData = await getLoggedinUser();
      if (!filial?.value && userData.role !== "super-admin") {
        return;
      }
      const filial_id = filial?.value || 0;
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
        filial_id,
        status: statusOrdem,
        forma_pagamento_id: formaPagamento,
        expediente_id: expediente,
        cliente_perfil: perfil === "todos" ? null : perfil,
      };

      const response = await apiClient.post("/ordens-servico/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setOrdensServico(dados.data);
        setTotalDeOrdensServico(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (
      userData.filiais.length > 0 &&
      !filial?.value &&
      userData.role !== "super-admin"
    ) {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
    }
  }, []);

  const fetchExpedientes = useCallback(async () => {
    const response = await apiClient.get("/expedientes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsExpedientes(
        dados.map((item) => ({
          label: `${item?.descricao}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFormasPagamento = useCallback(async () => {
    const response = await apiClient.get("/formas-pagamento");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFormasPagamento(
        dados.map((item) => ({
          label: `${item?.nome}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  const limparFiltros = useCallback(() => {
    setFilial({});
    setPaginaAtual(1);
    setLoadTable(true);
    setLoading(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchFiliais();
      await fetchFormasPagamento();
      await fetchExpedientes();
      await fetchServicos();
      await fetchOrdensServico(
        itensPorPagina,
        paginaAtual,
        termoBusca,
        statusOrdem,
        formaPagamento?.id,
        expediente?.id,
        clientePerfil,
        filial
      );
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    termoBusca,
    refresh,
    statusOrdem,
    formaPagamento,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Cadastro de Ordens de Serviço"
            pageTitle="Ordens de Serviço"
          />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Itens por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={3} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl="3">
                <label className="form-label">Status</label>
                <Input
                  id="statusOrdem"
                  className="form-control"
                  type="select"
                  value={statusOrdem}
                  onChange={(e) => setStatusOrdem(e.target.value)}
                >
                  <option value="todos">Todos</option>
                  <option value="novo">Novo</option>
                  <option value="pago">Pago</option>
                  <option value="em-andamento">Em Andamento</option>
                  <option value="inadimplente">Inadimplente</option>
                </Input>
              </Col>
              <Col xl="3">
                <label className="form-label">Filial</label>
                <Select
                  value={filial}
                  onChange={(sortBy) => {
                    setFilial(sortBy);
                    setRefresh(true);
                  }}
                  options={optionsFiliais}
                  id="filial"
                  placeholder={loading ? "Carregando..." : "Selecione a filial"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </Col>
            </Row>
          </Card>
          <Nav
            pills
            className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 mt-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: clientePerfil === "todos",
                })}
                onClick={() => {
                  arrowNavToggle("todos");
                }}
              >
                Todos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: clientePerfil === "fisica",
                })}
                onClick={() => {
                  arrowNavToggle("fisica");
                }}
              >
                Pessoa Física
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: clientePerfil === "juridica",
                })}
                onClick={() => {
                  arrowNavToggle("juridica");
                }}
              >
                Pessoa Jurídica
              </NavLink>
            </NavItem>
          </Nav>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Ordens de Serviço
                  </h4>
                  <div className="flex-shrink-0">
                    <Link
                      to="/nova-os"
                      className="btn btn-soft-secondary"
                      onClick={() => toggleModalNovo()}
                    >
                      Nova OS
                    </Link>
                    {filial?.value && (
                      <button
                        type="button"
                        className="btn btn-soft-danger ms-2"
                        onClick={() => limparFiltros()}
                      >
                        Limpar Filtros
                      </button>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Data</th>
                              <th scope="col">Cliente</th>
                              <th scope="col">Marca / Modelo</th>
                              <th scope="col">Placa</th>
                              <th scope="col">Serviços</th>
                              <th scope="col">Total</th>
                              <th scope="col">Status</th>
                              <th scope="col">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordensServico.length !== 0 &&
                              ordensServico.map((ordemServico, index) => {
                                const desconto =
                                  1 - parseFloat(ordemServico.desconto) / 100;
                                const total = parseFloat(ordemServico.total);
                                return (
                                  <tr key={index}>
                                    <td>{ordemServico?.id}</td>
                                    <td>
                                      <p className="mb-0 text-info">
                                        <b>
                                          {converterData(
                                            ordemServico?.data_realizado,
                                            false
                                          )}
                                        </b>
                                      </p>
                                      <p>
                                        <small className="text-muted">
                                          Expediente:{" "}
                                          {ordemServico?.expediente?.descricao}
                                        </small>
                                        <br />
                                        <small className="text-muted">
                                          <b>{ordemServico?.filial?.nome}</b>
                                        </small>
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        <b>{ordemServico?.cliente?.nome}</b>
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        {ordemServico?.veiculo?.modelo}
                                        <br />
                                        {ordemServico?.veiculo?.marca}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        <b>{ordemServico?.veiculo?.placa}</b>
                                      </p>
                                    </td>
                                    <td>
                                      {ordemServico?.servicos.map(
                                        (servico, index) => (
                                          <p
                                            key={index}
                                            className="badge bg-light text-dark me-1"
                                          >
                                            {servico?.servico}
                                          </p>
                                        )
                                      )}
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        <b>
                                          {formatCurrency(total * desconto)}
                                        </b>
                                      </p>
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        title="Mudar Status"
                                        onClick={() => {
                                          if (ordemServico?.status !== "pago")
                                            toggleModalMudarStatus(
                                              ordemServico
                                            );
                                        }}
                                      >
                                        {ordemServico?.status === "novo" ? (
                                          <p className="badge bg-primary-subtle text-primary">
                                            Nova
                                          </p>
                                        ) : ordemServico?.status === "pago" ? (
                                          <p className="badge bg-success-subtle text-success">
                                            Paga
                                          </p>
                                        ) : ordemServico?.status ===
                                          "em-andamento" ? (
                                          <p className="badge bg-warning-subtle text-warning">
                                            Em andamento
                                          </p>
                                        ) : ordemServico?.status ===
                                          "inadimplente" ? (
                                          <p className="badge bg-danger-subtle text-danger">
                                            Inadimplente
                                          </p>
                                        ) : (
                                          <p className="badge bg-light text-dark">
                                            {ordemServico?.status
                                              .charAt(0)
                                              .toUpperCase() +
                                              ordemServico?.status.slice(1)}
                                          </p>
                                        )}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Editar"
                                        onClick={() =>
                                          toggleModalEditar(ordemServico)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="edit"
                                          width="14"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        title="Apagar"
                                        onClick={() => {
                                          toggleModalApagar(ordemServico);
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="14"
                                        />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && ordensServico.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhuma ordem de serviço encontrada
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeOrdensServico
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeOrdensServico
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDeOrdensServico}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeOrdensServico}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}

                  <ModalNovo
                    modal={modalNovo}
                    setModal={setModalNovo}
                    setRefresh={setRefresh}
                    optionsFiliais={optionsFiliais}
                    optionsFormasPagamento={optionsFormasPagamento}
                    optionsExpedientes={optionsExpedientes}
                    optionsServicos={optionsServicos}
                  />
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    ordemServico={ordemServico}
                    optionsFormasPagamento={optionsFormasPagamento}
                    optionsExpedientes={optionsExpedientes}
                    optionsServicos={optionsServicos}
                    setRefresh={setRefresh}
                  />
                  <ModalMudarStatus
                    modal={modalMudarStatus}
                    setModal={setModalMudarStatus}
                    ordemServico={ordemServico}
                    setRefresh={setRefresh}
                    filial_id={filial?.value || 0}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    ordemServico={ordemServico}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default OrdensServico;
