import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

export const getContacts = createAsyncThunk("crm/getContacts" , async () => {
  return null;
})

export const getCompanies = createAsyncThunk("crm/getCompanies" , async () => {
  return null;
})

export const addNewCompanies = createAsyncThunk("crm/addNewCompanies" , async (companies) => {
  return companies;
})

export const updateCompanies = createAsyncThunk("crm/updateCompanies" , async (companies) => {
  return companies;
})

export const deleteCompanies = createAsyncThunk("crm/deleteCompanies" , async (companies) => {
  return companies;
})

export const addNewContact = createAsyncThunk("crm/addNewContact" , async (contact) => {
  return contact;
})

export const updateContact = createAsyncThunk("crm/updateContact" , async (contact) => {
  return contact;
})

export const deleteContact = createAsyncThunk("crm/deleteContact" , async (contact) => {
  return contact;
})

export const getLeads = createAsyncThunk("crm/getLeads" , async () => {
  return null;
})

export const addNewLead = createAsyncThunk("crm/addNewLead" , async (lead) => {
  return lead;
})

export const updateLead = createAsyncThunk("crm/updateLead" , async (lead) => {
  return lead;
})

export const deleteLead = createAsyncThunk("crm/deleteLead" , async (lead) => {
  return lead;
})

export const getDeals = createAsyncThunk("crm/getDeals" , async () => {
  return null;
})