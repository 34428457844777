import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalEditar = ({
  modal,
  setModal,
  setRefresh,
  fornecedores,
  optionsFiliais,
  itens,
  tipo,
  entrada,
}) => {
  const [loading, setLoading] = useState(false);
  const [optionsRevendedores, setOptionsRevendedores] = useState([]);
  const [optionsItens, setOptionsItens] = useState([]);
  const [fornecedor, setFornecedor] = useState({});
  const [filial, setFilial] = useState({});
  const [item, setItem] = useState({});
  const [quantidade, setQuantidade] = useState("");
  const [valor_unitario, setValorUnitario] = useState("");
  const [origem, setOrigem] = useState("Compra");

  const toggle = () => {
    setFilial("");
    setFornecedor("");
    setItem("");
    setQuantidade("");
    setValorUnitario("");
    setOrigem("Compra");

    setModal(!modal);
  };

  const editar = async (entrada, tipo) => {
    setLoading(true);

    const data = {
      item_id: item?.value,
      filial_id: filial?.value,
      fornecedor_id: fornecedor?.value,
      quantidade,
      valor_unitario: valor_unitario === "" ? 0 : valor_unitario,
      fonte: origem,
      tipo,
    };

    const response = await apiClient.put(`/estoque/${entrada.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetch = async (entrada) => {
      setLoading(true);
      setOptionsRevendedores(
        fornecedores.map((fornecedor) => ({
          label: `${fornecedor?.nome}`,
          value: `${fornecedor?.id}`,
        }))
      );
      setOptionsItens(
        itens.map((item) => ({
          label: `${item?.nome} [${item?.marca?.nome}]`,
          medida: `${item?.medida?.nome}`,
          value: `${item?.id}`,
        }))
      );

      setFilial({
        label: `${entrada?.filial?.nome}`,
        value: `${entrada?.filial?.id}`,
      });

      if (tipo === "entradas") {
        setFornecedor({
          label: `${entrada?.fornecedor?.nome}`,
          value: `${entrada?.fornecedor?.id}`,
        });
      }

      setItem({
        label: `${entrada?.item?.nome} [${entrada?.item?.marca?.nome}]`,
        medida: `${entrada?.item?.medida?.nome}`,
        value: `${entrada?.item?.id}`,
      });

      setQuantidade(entrada?.quantidade);
      setValorUnitario(entrada?.valor_unitario);
      setOrigem(
        tipo === "entradas" ? entrada?.fonte_entrada : entrada?.destino_saida
      );

      setLoading(false);
    };
    if (modal) {
      fetch(entrada);
    }
  }, [modal, optionsFiliais, fornecedores, itens]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nova {tipo === "entradas" ? "Entrada" : "Saída"} de Estoque
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md={tipo === "entradas" ? 6 : 12}>
              <div>
                <Label htmlFor="filial" className="form-label">
                  Filial <span className="text-danger">*</span>
                </Label>
                <Select
                  value={filial}
                  onChange={(sortBy) => {
                    setFilial(sortBy);
                  }}
                  options={optionsFiliais}
                  id="filial"
                  placeholder={loading ? "Carregando..." : "Selecione a filial"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </div>
            </Col>

            {tipo === "entradas" && (
              <Col md="6">
                <div>
                  <Label htmlFor="fornecedor" className="form-label">
                    Fornecedor <span className="text-danger">*</span>
                  </Label>
                  <Select
                    value={fornecedor}
                    onChange={(sortBy) => {
                      setFornecedor(sortBy);
                    }}
                    options={optionsRevendedores}
                    id="fornecedor"
                    placeholder={
                      loading ? "Carregando..." : "Selecione o fornecedor"
                    }
                    disabled={loading}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #ced4da",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="item" className="form-label">
                  Item <span className="text-danger">*</span>
                </Label>
                <Select
                  value={item}
                  onChange={(sortBy) => {
                    setItem(sortBy);
                  }}
                  options={optionsItens}
                  id="item"
                  placeholder={loading ? "Carregando..." : "Selecione o item"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="quantidade" className="form-label">
                  Quantidade
                  <span className="text-danger">*</span>
                </Label>
                <NumericFormat
                  id="quantidade"
                  placeholder={`0,00 ${item?.medida || ""}`}
                  className="form-control"
                  value={quantidade}
                  onValueChange={({ value }) => setQuantidade(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  suffix={` ${item?.medida}` || ""}
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="valor_unitario" className="form-label">
                  Valor Unitário <span className="text-danger">*</span>
                </Label>
                <NumericFormat
                  id="valor_unitario"
                  placeholder="R$ 0,00"
                  className="form-control"
                  value={valor_unitario}
                  onValueChange={({ value }) => setValorUnitario(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="origem" className="form-label">
                  Origem <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  id="origem"
                  placeholder="Origem"
                  value={origem}
                  onChange={(e) => setOrigem(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar(entrada, tipo)}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
