import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllData = createAsyncThunk("dashboardAnalytics/getAllData", async (data) => {
  return data;
});

export const getAudiencesMetricsChartsData = createAsyncThunk("dashboardAnalytics/getAudiencesMetricsChartsData", async (data) => {
  return data;
});

export const getUserDeviceChartsData = createAsyncThunk("dashboardAnalytics/getUserDeviceChartsData", async (data) => {
  return data;
});

export const getAudiencesSessionsChartsData = createAsyncThunk("dashboardAnalytics/getAudiencesSessionsChartsData", async (data) => {
  return data;
});