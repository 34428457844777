import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"


export const userForgetPassword = (user, history) => async (dispatch) => {
  try {
      let response;
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

          response = history;

      } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
          response = user;
      } else {
          response = null;
      }

      const data = await response;

      if (data) {
          dispatch(userForgetPasswordSuccess(
              "Reset link are sended to your mailbox, check there first"
          ))
      }
  } catch (forgetError) {
      dispatch(userForgetPasswordError(forgetError))
  }
}