import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import {
  Spinner
} from "reactstrap";

const AuthProtected = (props) => {
  const [userProfile, setUserProfile] = useState(null);
  const [isChecking, setIsChecking] = useState(true);

  const lastLogin = localStorage.getItem("lastLogin");
  const timeDiff = Date.now() - (lastLogin || 6.1 * 60 * 60 * 1000);
  if (timeDiff >= 6 * 60 * 60 * 1000) {
    localStorage.removeItem("authUser");
    localStorage.removeItem("lastUpdated");
    localStorage.removeItem("lastLogin");

    window.location.href = "/login";
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUserProfile(parsedUser);
      } catch (error) {
        console.error("Error parsing authUser from localStorage", error);
      }
    }
    setIsChecking(false);
  }, []);

  if (isChecking) {
    return <Spinner color="primary" />;
  }

  if (!userProfile || !userProfile.token) {
    return <Navigate to="/login" state={{ from: props.location }} replace />;
  }

  return <>{props.children}</>;
};


const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };