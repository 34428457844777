import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Select from "react-select";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";

import { toast } from "react-toastify";
import {
  checkLastLogin,
  converterData,
  formatCurrency,
} from "../../Components/FunctionsRepository";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const EntradaSaidaEstoque = () => {
  document.title = `Entradas e Saídas de Estoque | ${process.env.REACT_APP_TITLE}`;

  const [userDados, setUserDados] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [fornecedores, setFornecedores] = useState([]);
  const [itens, setItens] = useState([]);
  const [optionsFornecedores, setOptionsFornecedores] = useState([]);
  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [optionsItens, setOptionsItens] = useState([]);
  const [fornecedor, setFornecedor] = useState({});
  const [filial, setFilial] = useState({});
  const [item, setItem] = useState({});

  const [entradas, setEntradas] = useState([]);
  const [entrada, setEntrada] = useState(null);
  const [tipo, setTipo] = useState("entradas");
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeEntradas, setTotalDeEntradas] = useState(0);

  const [modalNovo, setModalNovo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);

  const arrowNavToggle = (tab) => {
    if (tipo !== tab) {
      setTipo(tab);
      setFornecedor({});
      setPaginaAtual(1);
      setLoadTable(true);
      setLoading(true);
    }
  };

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalEditar = useCallback(
    (seg) => {
      setEntrada(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalApagar = useCallback(
    (seg) => {
      setEntrada(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const fetchItens = useCallback(async () => {
    const response = await apiClient.get("/itens");
    if (response.sucesso) {
      const dados = response.dados;
      setItens(dados);
      setOptionsItens(
        dados.map((item) => ({
          label: `${item?.nome} [${item?.marca?.nome}]`,
          medida: `${item?.medida?.nome}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFornecedores = useCallback(async () => {
    const response = await apiClient.get("/fornecedores");
    if (response.sucesso) {
      const dados = response.dados;
      setFornecedores(dados);
      setOptionsFornecedores(
        dados.map((fornecedor) => ({
          label: `${fornecedor?.nome}`,
          value: `${fornecedor?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (userData.filiais.length > 0 && !filial?.value && userData.role !== "super-admin") {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
    }
  }, []);

  const fetchEntradas = useCallback(
    async (
      itensPorPagina,
      tipo,
      paginaAtual,
      item_id,
      fornecedor_id,
      filial
    ) => {
      const userData = await getLoggedinUser();
      if (!filial?.value && userData.role !== "super-admin") {
        return;
      }
      const filial_id = filial?.value || 0;
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        tipo,
        filial_id,
        fornecedor_id,
        item_id,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/estoque/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setEntradas(dados.data);
        setTotalDeEntradas(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const limparFiltros = useCallback(() => {
    setFornecedor({});
    setFilial({});
    setItem({});
    setPaginaAtual(1);
    setLoadTable(true);
    setLoading(true);
  }, []);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      const userData = await getLoggedinUser();
      setUserDados(userData);

      await fetchEntradas(
        itensPorPagina,
        tipo,
        paginaAtual,
        item?.value,
        fornecedor?.value,
        filial
      );
      await fetchFornecedores();

      await fetchFiliais();

      await fetchItens();

      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    refresh,
    tipo,
    item,
    fornecedor,
    filial,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Entradas e Saídas" pageTitle="Estoque" />
          <Card>
            <Row className="justify-content-between align-items-center m-3">
              <Col xl={3}>
                <label className="form-label">Itens por página</label>
                <select
                  className="form-select"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              {tipo === "entradas" && (
                <Col xl="3">
                  <label className="form-label">Fornecedor</label>
                  <Select
                    value={fornecedor}
                    onChange={(sortBy) => {
                      setFornecedor(sortBy);
                    }}
                    options={optionsFornecedores}
                    id="fornecedor"
                    placeholder={
                      loading ? "Carregando..." : "Selecione o fornecedor"
                    }
                    disabled={loading}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #ced4da",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                </Col>
              )}
              <Col xl="3">
                <label className="form-label">Filial</label>
                <Select
                  value={filial}
                  onChange={(sortBy) => {
                    setFilial(sortBy);
                    setRefresh(true);
                  }}
                  options={optionsFiliais}
                  id="filial"
                  placeholder={loading ? "Carregando..." : "Selecione a filial"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </Col>
              <Col md="3">
                <label className="form-label">Item</label>
                <Select
                  value={item}
                  onChange={(sortBy) => {
                    setItem(sortBy);
                  }}
                  options={optionsItens}
                  id="item"
                  placeholder={loading ? "Carregando..." : "Selecione o item"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </Col>
            </Row>
          </Card>

          <Nav
            pills
            className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 mt-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: tipo === "entradas",
                })}
                onClick={() => {
                  arrowNavToggle("entradas");
                }}
              >
                Entradas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: tipo === "saidas",
                })}
                onClick={() => {
                  arrowNavToggle("saidas");
                }}
              >
                Saídas
              </NavLink>
            </NavItem>
          </Nav>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {tipo === "entradas" ? "Entradas" : "Saídas"}
                  </h4>
                  <div className="flex-shrink-0">
                    {tipo === "entradas" ? (
                      <button
                        type="button"
                        className="btn btn-soft-secondary"
                        onClick={() => toggleModalNovo()}
                      >
                        Nova Entrada
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-soft-danger"
                        onClick={() => toggleModalNovo()}
                      >
                        Nova Saída
                      </button>
                    )}
                    {(item?.value || fornecedor?.value || filial?.value) && (
                      <button
                        type="button"
                        className="btn btn-soft-danger ms-2"
                        onClick={() => limparFiltros()}
                      >
                        Limpar Filtros
                      </button>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">
                                Data /{" "}
                                {tipo === "entradas" ? "Origem" : "Destino"}
                              </th>
                              <th scope="col">Item</th>
                              {tipo === "entradas" && (
                                <th scope="col">Fornecedor</th>
                              )}
                              <th scope="col">Quantidade</th>
                              {tipo === "entradas" && (
                                <th scope="col">Valor Unitário</th>
                              )}
                              <th scope="col">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {entradas.length !== 0 &&
                              entradas.map((entrada, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-info">
                                      <p className="mb-0">
                                        <b>
                                          {converterData(entrada.created_at)}{" "}
                                        </b>
                                      </p>
                                      {tipo === "entradas" ? (
                                        <small className="text-muted">
                                          <b>Origem: </b>
                                          {entrada.fonte_entrada}
                                        </small>
                                      ) : (
                                        <small className="text-muted">
                                          <b>Destino: </b>
                                          {entrada.destino_saida}
                                        </small>
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{entrada.item?.nome}</b>
                                      </p>
                                      <small className="text-muted">
                                        <b>Filial: </b>
                                        {entrada.filial?.nome}
                                      </small>
                                    </td>
                                    {tipo === "entradas" && (
                                      <td>{entrada.fornecedor?.nome}</td>
                                    )}
                                    <td>
                                      {parseFloat(entrada.quantidade).toFixed(
                                        2
                                      )}{" "}
                                      <small className="text-muted">
                                        {entrada.item?.medida?.nome}
                                      </small>
                                    </td>
                                    {tipo === "entradas" && (
                                      <td>
                                        {formatCurrency(entrada.valor_unitario)}
                                      </td>
                                    )}
                                    <td>
                                      {(userDados?.role === "super-admin" ||
                                        userDados?.role === "admin") && (
                                        <>
                                          <Link
                                            to="#"
                                            className="text-success"
                                            title="Corrigir"
                                            onClick={() =>
                                              toggleModalEditar(entrada)
                                            }
                                          >
                                            <FeatherIcon
                                              icon="edit"
                                              width="14"
                                              className="me-3"
                                            />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            title="Apagar"
                                            onClick={() => {
                                              toggleModalApagar(entrada);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="trash-2"
                                              width="14"
                                            />
                                          </Link>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && entradas.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhuma entrada encontrada
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeEntradas
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeEntradas
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDeEntradas}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeEntradas}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}

                  <ModalNovo
                    modal={modalNovo}
                    setModal={setModalNovo}
                    setRefresh={setRefresh}
                    itens={itens}
                    fornecedores={fornecedores}
                    optionsFiliais={optionsFiliais}
                    tipo={tipo}
                  />
                  {(userDados?.role === "super-admin" ||
                    userDados?.role === "admin") && (
                    <>
                      <ModalEditar
                        modal={modalEditar}
                        setModal={setModalEditar}
                        entrada={entrada}
                        setRefresh={setRefresh}
                        itens={itens}
                        fornecedores={fornecedores}
                        optionsFiliais={optionsFiliais}
                        tipo={tipo}
                      />
                      <ModalApagar
                        modal={modalApagar}
                        setModal={setModalApagar}
                        entrada={entrada}
                        setRefresh={setRefresh}
                        tipo={tipo}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default EntradaSaidaEstoque;
