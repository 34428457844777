import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import Select from "react-select";

import SearchOption from "../../Components/Common/SearchOption";
import ModalEditar from "./ModalEditar";

import { toast } from "react-toastify";
import {
  checkLastLogin,
  converterData,
  formatCurrency,
} from "../../Components/FunctionsRepository";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const PlanosPagamentos = () => {
  document.title = `Pagamentos | ${process.env.REACT_APP_TITLE}`;

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [pagamentos, setPagamentos] = useState([]);
  const [pagamento, setPagamento] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDePagamentos, setTotalDePagamentos] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [filial, setFilial] = useState({});

  const [modalEditar, setModalEditar] = useState(false);

  const toggleModalEditar = useCallback(
    (seg) => {
      setPagamento(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (userData.filiais.length > 0 && !filial?.value && userData.role !== "super-admin") {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
    }
  }, []);

  const fetchPagamentos = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca, filial) => {
      const userData = await getLoggedinUser();
      if (!filial?.value && userData.role !== "super-admin") {
        return;
      }
      const filial_id = filial?.value || 0;
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        filial_id,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/planos-pagamento/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setPagamentos(dados.data);
        setTotalDePagamentos(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const limparFiltros = useCallback(() => {
    setTermoBusca("");
    setPaginaAtual(1);
    setFilial({});
    setRefresh(true);
  }, []);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchFiliais();
      await fetchPagamentos(itensPorPagina, paginaAtual, termoBusca, filial);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    termoBusca,
    refresh,
    filial,
    fetchPagamentos,
    refreshUser,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cadastro Pagamentos" pageTitle="Pagamentos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Itens por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={6} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl="3">
                <label className="form-label">Filial</label>
                <Select
                  value={filial}
                  onChange={(sortBy) => {
                    setFilial(sortBy);
                    setRefresh(true);
                  }}
                  options={optionsFiliais}
                  id="filial"
                  placeholder={loading ? "Carregando..." : "Selecione a filial"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Pagamentos</h4>
                  <div className="flex-shrink-0">
                    {filial?.value && (
                      <button
                        type="button"
                        className="btn btn-soft-danger ms-2"
                        onClick={() => limparFiltros()}
                      >
                        Limpar Filtros
                      </button>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Data</th>
                              <th scope="col">Cliente</th>
                              <th scope="col">Plano</th>
                              <th scope="col">Valor</th>
                              <th scope="col">Status</th>
                              <th scope="col">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pagamentos.length !== 0 &&
                              pagamentos.map((pagamento, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {converterData(pagamento.created_at)}
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{pagamento?.cliente?.nome}</b>
                                      </p>
                                    </td>

                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0 text-primary">
                                        <b>{pagamento?.plano?.nome}</b>
                                      </p>
                                    </td>
                                    <td className="text-success">
                                      {formatCurrency(pagamento?.valor_total)}
                                    </td>
                                    <td>
                                      {pagamento?.status === "novo" ? (
                                        <p className="badge bg-primary-subtle text-primary">
                                          Novo
                                        </p>
                                      ) : pagamento?.status === "pago" ? (
                                        <p className="badge bg-success-subtle text-success">
                                          Pago
                                        </p>
                                      ) : pagamento?.status === "cancelado" ? (
                                        <p className="badge bg-danger-subtle text-danger">
                                          Cancelado
                                        </p>
                                      ) : (
                                        <p className="badge bg-light text-dark">
                                          {pagamento?.status
                                            .charAt(0)
                                            .toUpperCase() +
                                            ordemServico?.status.slice(1)}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Editar"
                                        onClick={() =>
                                          toggleModalEditar(pagamento)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="edit"
                                          width="14"
                                          className="me-3"
                                        />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && pagamentos.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum pagamento encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDePagamentos
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDePagamentos
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDePagamentos}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDePagamentos}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    pagamento={pagamento}
                    setRefresh={setRefresh}
                    formatCurrency={formatCurrency}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default PlanosPagamentos;
