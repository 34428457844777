import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin, nomeMask } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({
  modal,
  setModal,
  setRefresh,
  marcas,
  categorias,
  medidas,
  item,
}) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [funcao, setFuncao] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [medida, setMedida] = useState("");
  const [descricao, setDescricao] = useState("");

  const toggle = () => {
    setNome("");
    setDescricao("");
    setFuncao("");
    setMarca("");
    setCategoria("");
    setMedida("");

    setModal(!modal);
  };

  const editar = async (item) => {
    setLoading(true);

    const data = {
      nome: nomeMask(nome),
      marca_id: marca,
      categoria_id: categoria,
      medida_id: medida,
      descricao,
      funcao,
    };

    const response = await apiClient.put(`/itens/${item.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async (item) => {
      setNome(item.nome);
      setDescricao(item.descricao);
      setFuncao(item.funcao);
      setMarca(item.marca_id);
      setCategoria(item.categoria_id);
      setMedida(item.medida_id);
    };
    if (item) {
      fetchData(item);
    }
  }, [item]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Novo Item
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="marca" className="form-label">
                  Marca <span className="text-danger">*</span>
                </Label>
                <Input
                  id="marca"
                  className="form-control"
                  type="select"
                  value={marca}
                  onChange={(e) => setMarca(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  {marcas.map((marca) => (
                    <option key={marca.id} value={marca.id}>
                      {marca.nome}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="medida" className="form-label">
                  Medida <span className="text-danger">*</span>
                </Label>
                <Input
                  id="medida"
                  className="form-control"
                  type="select"
                  value={medida}
                  onChange={(e) => setMedida(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  {medidas.map((medida) => (
                    <option key={medida.id} value={medida.id}>
                      {medida.nome}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="categoria" className="form-label">
                  Categoria <span className="text-danger">*</span>
                </Label>
                <Input
                  id="categoria"
                  className="form-control"
                  type="select"
                  value={categoria}
                  onChange={(e) => setCategoria(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nome}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="funcao" className="form-label">
                  Função
                </Label>
                <Input
                  id="funcao"
                  className="form-control"
                  type="text"
                  placeholder="Função"
                  value={funcao}
                  onChange={(e) => setFuncao(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="descricao" className="form-label">
                  Descrição
                </Label>
                <Input
                  id="descricao"
                  className="form-control"
                  type="textarea"
                  placeholder="Descrição"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar(item)}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
