import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  checkLastLogin,
  formatCurrency,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, plano }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [preco, setPreco] = useState("");
  const [servico, setServico] = useState({});
  const [quantidade, setQuantidade] = useState(1);
  const [desconto, setDesconto] = useState("");
  const [optionsServicos, setOptionsServicos] = useState([]);
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [quantidadeServicos, setQuantidadeServicos] = useState([]);
  const [descontoServicos, setDescontoServicos] = useState([]);

  const adicionarServico = () => {
    if (servico?.value) {
      let descontoAplicar = parseFloat(desconto === "" ? "0" : desconto) / 100;
      const servicoIndex = servicosSelecionados.findIndex(
        (s) => s.value === servico.value
      );

      if (servicoIndex !== -1) {
        // Serviço já existe, atualize apenas a quantidade e o preço
        let precoAtual = parseFloat(preco === "" ? "0" : preco);

        let listaQuantidadeAntiga = quantidadeServicos;
        let listaDescontoAntigo = descontoServicos;
        let quantidadeAntiga = listaQuantidadeAntiga[servicoIndex];
        let descontoAntigo = listaDescontoAntigo[servicoIndex];

        listaQuantidadeAntiga[servicoIndex] += parseInt(quantidade);
        listaDescontoAntigo[servicoIndex] = descontoAplicar;
        setQuantidadeServicos(listaQuantidadeAntiga);
        setDescontoServicos(listaDescontoAntigo);

        //remover calculo do preço antigo
        let precoAntigo =
          parseFloat(servicosSelecionados[servicoIndex].preco) *
          quantidadeAntiga *
          (1 - descontoAntigo);
        let novoPreco = precoAtual - precoAntigo;

        //adicionar calculo do preço novo
        let precoAdicional =
          parseFloat(servico.preco) *
          (1 - descontoAplicar) *
          listaQuantidadeAntiga[servicoIndex];
        setPreco((novoPreco + precoAdicional).toFixed(2));
      } else {
        // Serviço não existe, adicione à lista e atualize a quantidade e o preço
        const quantidadeNumber = parseInt(quantidade);
        setServicosSelecionados([...servicosSelecionados, servico]);
        setQuantidadeServicos([...quantidadeServicos, quantidadeNumber]);
        setDescontoServicos([...descontoServicos, descontoAplicar]);

        const precoAtual = parseFloat(preco === "" ? "0" : preco);
        const precoAdicional =
          parseFloat(servico.preco) * (1 - descontoAplicar) * quantidadeNumber;
        setPreco((precoAtual + precoAdicional).toFixed(2));
      }

      setServico({});
      setQuantidade(1);
    }
  };

  const removerServico = (index) => {
    const newServicos = servicosSelecionados.filter(
      (servico, i) => i !== index
    );
    const newQuantidades = quantidadeServicos.filter(
      (quantidade, i) => i !== index
    );
    const newDescontos = descontoServicos.filter((desconto, i) => i !== index);
    const servicoRemovido = servicosSelecionados[index];
    const quantidadeRemovida = quantidadeServicos[index];
    const descontoRemovido = descontoServicos[index];
    setServicosSelecionados(newServicos);
    setQuantidadeServicos(newQuantidades);
    setDescontoServicos(newDescontos);

    //remover calculo do preço antigo
    let precoAtual = parseFloat(preco === "" ? "0" : preco);
    let precoRemovido =
      parseFloat(servicoRemovido.preco) *
      quantidadeRemovida *
      (1 - descontoRemovido);
    let novoPreco = precoAtual - precoRemovido;
    setPreco(novoPreco.toFixed(2));
  };

  const toggle = () => {
    setNome("");
    setDescricao("");
    setPreco("");
    setDesconto("");
    setServico({});
    setQuantidade(1);
    setServicosSelecionados([]);
    setQuantidadeServicos([]);
    setDescontoServicos([]);

    setModal(!modal);
  };

  const editar = async (plano) => {
    setLoading(true);

    const servicosIds = servicosSelecionados.map((servico) => servico.value);

    const data = {
      nome,
      descricao,
      preco: preco === "" ? "0" : preco,
      servicos: servicosIds,
      quantidades: quantidadeServicos,
      descontos: descontoServicos.map((desconto) => desconto * 100),
    };

    const response = await apiClient.put(`/planos/${plano?.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchServicos = useCallback(async () => {
    const response = await apiClient.get("/servicos");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsServicos(
        dados.map((servico) => ({
          label: `${servico?.servico}`,
          preco: `${servico?.preco}`,
          value: `${servico?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetch = async (plano) => {
      await fetchServicos();

      setNome(plano?.nome);
      setDescricao(plano?.descricao);
      setPreco(plano?.preco);

      const servicosPlano = plano?.servicos;

      setServicosSelecionados(
        servicosPlano.map((servico) => ({
          label: `${servico?.servico}`,
          value: `${servico?.id}`,
          preco: `${servico?.preco}`,
        }))
      );

      setQuantidadeServicos(
        servicosPlano.map((servico) => parseFloat(servico?.pivot?.quantidade))
      );

      setDescontoServicos(
        servicosPlano.map(
          (servico) => parseFloat(servico?.pivot?.desconto) / 100
        )
      );
    };
    if (modal) fetch(plano);
  }, [modal, fetchServicos]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar {plano?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="descricao" className="form-label">
                  Descrição
                </Label>
                <Input
                  id="descricao"
                  className="form-control"
                  type="textarea"
                  placeholder="Descrição"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="preco" className="form-label">
                  Preço <span className="text-danger">*</span>
                </Label>
                <NumericFormat
                  id="preco"
                  placeholder="R$ 0,00"
                  className="form-control"
                  value={preco}
                  onValueChange={({ value }) => setPreco(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                  readOnly
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="3">
              <Label htmlFor="servicos" className="form-label">
                Serviços
              </Label>
              <Select
                value={servico}
                onChange={(sortBy) => {
                  setServico(sortBy);
                }}
                options={optionsServicos}
                id="servicos"
                placeholder={loading ? "Carregando..." : "Selecione o serviço"}
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="3">
              <div>
                <Label htmlFor="quantidade" className="form-label">
                  Quantidade
                  <span className="text-danger">*</span>
                </Label>
                <NumericFormat
                  id="quantidade"
                  placeholder="0"
                  className="form-control"
                  value={quantidade}
                  onValueChange={({ value }) => setQuantidade(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={0}
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md="3">
              <div>
                <Label htmlFor="desconto" className="form-label">
                  Desconto
                </Label>
                <NumericFormat
                  id="desconto"
                  placeholder="0,00 %"
                  className="form-control"
                  value={desconto}
                  onValueChange={({ value }) => setDesconto(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  suffix=" %"
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md="3" className="pt-2">
              <button
                className="btn btn-sm btn-success mt-4"
                onClick={() => adicionarServico()}
              >
                Adicionar
              </button>
            </Col>
          </Row>

          {/* tabela com os servicos selecionados e quantidades */}
          <Row className="mt-3">
            <Col md="12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Serviço</th>
                    <th>Quantidade</th>
                    <th>Desconto</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {servicosSelecionados.map((servico, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {servico.label}
                      </td>
                      <td>{quantidadeServicos[index]}</td>
                      <td>{descontoServicos[index] * 100}%</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => removerServico(index)}
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar(plano)}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
