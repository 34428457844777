import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Label,
  Input,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-select";
import {
  checkLastLogin,
  formatEmail,
  nomeMask,
  trataUsename,
} from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const NovoUsuario = () => {
  document.title = `Novo Usuário | ${process.env.REACT_APP_TITLE}`;

  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [login, setLogin] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState("usuario");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [funcoesSelecionadas, setFuncoesSelecionadas] = useState([]);
  const [optionsFuncoes, setOptionsFuncoes] = useState([]);
  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [filial, setFilial] = useState({});

  const selecionarTodasFuncoes = () => {
    setFuncoesSelecionadas(optionsFuncoes);
  };

  const deselecionarTodasFuncoes = () => {
    setFuncoesSelecionadas([]);
  };

  const criar = async () => {
    setLoading(true);

    const funcoesIds = funcoesSelecionadas.map((funcao) => funcao.value);

    const data = {
      nome: nomeMask(nome),
      login,
      cpf,
      email,
      telefone,
      tipo,
      password,
      password_confirmation: confirmPassword,
      funcoes: funcoesIds,
      filial_id: filial?.value,
    };

    const response = await apiClient.post("/usuarios", data);
    if (response.sucesso) {
      toast.success(response.mensagem);

      window.location.href = "/usuarios";
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchFuncoes = useCallback(async () => {
    const response = await apiClient.get("/funcoes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFuncoes(
        dados.map((funcao) => ({
          label: `${funcao?.funcao}`,
          value: `${funcao?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (
      userData.filiais.length > 0 &&
      !filial?.value &&
      userData.role !== "super-admin"
    ) {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
    }
  }, []);

  const handleCheckboxChange = (funcao) => {
    if (funcoesSelecionadas.includes(funcao)) {
      setFuncoesSelecionadas(funcoesSelecionadas.filter((f) => f !== funcao));
    } else {
      setFuncoesSelecionadas([...funcoesSelecionadas, funcao]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchFuncoes();
      await fetchFiliais();
    };

    fetchData();
    setLoading(false);
  }, [loading]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cadastro de Usuários" pageTitle="Usuários" />
          <Card>
            <CardBody>
              <div className="mt-4">
                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="nome" className="form-label">
                        Nome <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="nome"
                        className="form-control"
                        type="text"
                        placeholder="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        maxLength={150}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <Label htmlFor="cpf" className="form-label">
                        CPF
                      </Label>
                      <InputMask
                        mask="999.999.999-99"
                        placeholder="999.999.999-99"
                        defaultValue={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="4">
                    <div>
                      <Label htmlFor="login" className="form-label">
                        Login <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="login"
                        className="form-control"
                        type="text"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(trataUsename(e.target.value))}
                        maxLength={30}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div>
                      <Label htmlFor="email" className="form-label">
                        E-mail
                      </Label>
                      <Input
                        id="email"
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(formatEmail(e.target.value))}
                        maxLength={150}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div>
                      <Label htmlFor="telefone" className="form-label">
                        Telefone
                      </Label>
                      <InputMask
                        mask="(99) 99999-9999"
                        placeholder="(11) 99999-9999"
                        defaultValue={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="password" className="form-label">
                        Senha
                      </Label>
                      <Input
                        id="password"
                        className="form-control"
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        maxLength={20}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <Label htmlFor="confirmPassword" className="form-label">
                        Confirmar Senha
                      </Label>
                      <Input
                        id="confirmPassword"
                        className="form-control"
                        type="password"
                        placeholder="Confirmar Senha"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        maxLength={20}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <Label htmlFor="tipo" className="form-label">
                      Tipo
                    </Label>
                    <Input
                      type="select"
                      id="tipo"
                      value={tipo}
                      onChange={(e) => setTipo(e.target.value)}
                      className="form-control"
                    >
                      <option value="usuario">Usuário</option>
                      <option value="admin">Administrador</option>
                      <option value="super-admin">Super Administrador</option>
                    </Input>
                  </Col>
                  <Col md="6">
                    <Label htmlFor="filial" className="form-label">
                      Filial <span className="text-danger">*</span>
                    </Label>
                    <Select
                      value={filial}
                      onChange={(sortBy) => {
                        setFilial(sortBy);
                      }}
                      options={optionsFiliais}
                      id="filial"
                      placeholder={
                        loading ? "Carregando..." : "Selecione a filial"
                      }
                      disabled={loading}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #ced4da",
                          color: "#aaa",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#aaa",
                        }),
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Label htmlFor="funcoes" className="form-label">
                    Funções
                  </Label>

                  <Row className="mt-3">
                    <Col md="6">
                      <Button
                        color="primary"
                        className="btn btn-sm"
                        onClick={() => selecionarTodasFuncoes()}
                        disabled={loading}
                      >
                        Selecionar todas
                      </Button>
                      <Button
                        color="secondary"
                        className="btn btn-sm ms-2"
                        onClick={() => deselecionarTodasFuncoes()}
                        disabled={loading}
                      >
                        Desmarcar todas
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {optionsFuncoes.map((funcao) => (
                      <Col md="3" key={funcao.value}>
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            id={`funcao-${funcao.value}`}
                            checked={funcoesSelecionadas.includes(funcao)}
                            onChange={() => handleCheckboxChange(funcao)}
                            disabled={loading}
                          />
                          <Label
                            htmlFor={`funcao-${funcao.value}`}
                            className="form-check-label"
                          >
                            {funcao.label}
                          </Label>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Row>

                <div className="hstack gap-2 justify-content-end mt-5">
                  <Link
                    to="#"
                    className="btn btn-link link-secondary fw-medium"
                    onClick={() => toggle()}
                    disabled={loading}
                  >
                    <i className="ri-close-line me-1 align-middle"></i> Fechar
                  </Link>
                  <button
                    className="btn btn-secondary"
                    onClick={() => criar()}
                    disabled={loading}
                  >
                    Criar
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </Fragment>
  );
};

export default NovoUsuario;
