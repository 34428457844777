import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProducts = createAsyncThunk("ecommerce/getProducts", async () => {
  return null;
});

export const getOrders = createAsyncThunk("ecommerce/getOrders", async () => {
  return null;
});

export const getSellers = createAsyncThunk("ecommerce/getSellers", async () => {
  return null;
});

export const getCustomers = createAsyncThunk("ecommerce/getCustomers", async () => {
  return null;
});

export const deleteProducts = createAsyncThunk("ecommerce/deleteProducts", async (product) => {
  return product;
});

export const updateOrder = createAsyncThunk("ecommerce/updateOrder", async (product) => {
  return product;
});

export const addNewProduct = createAsyncThunk("ecommerce/addNewProduct", async (product) => {
  return product;
});

export const updateProduct = createAsyncThunk("ecommerce/updateProduct", async (product) => {
  return product;
});

export const deleteOrder = createAsyncThunk("ecommerce/deleteOrder", async (product) => {
  return product;
});

export const addNewOrder = createAsyncThunk("ecommerce/addNewOrder", async (product) => {
  return product;
});

export const updateCustomer = createAsyncThunk("ecommerce/updateCustomer", async (product) => {
  return product;
});

export const deleteCustomer = createAsyncThunk("ecommerce/deleteCustomer", async (product) => {
  return product;
});

export const addNewCustomer = createAsyncThunk("ecommerce/addNewCustomer", async (product) => {
  return product;
});