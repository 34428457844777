import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDirectContact = createAsyncThunk("chat/getDirectContact", async () => {
  return null;
});

export const getChannels = createAsyncThunk("chat/getChannels" , async () => {
  return null;
});

export const getMessages = createAsyncThunk("chat/getMessages" , async (roomId) => {
  return roomId;
});

export const addMessage = createAsyncThunk("chat/addMessage" , async (message) => {
  return message;
});

export const deleteMessage = createAsyncThunk("chat/deleteMessage" , async (message) => {
  return message;
});