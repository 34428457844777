import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTaskList = createAsyncThunk("tasks/getTaskList", async () => {
  return null;
});

export const addNewTask = createAsyncThunk("tasks/addNewTask", async (task) => {
  return task;
});

export const updateTask = createAsyncThunk("tasks/updateTask", async (task) => {
  return task;
});

export const deleteTask = createAsyncThunk("tasks/deleteTask", async (task) => {
  return task;
});

// Kanban Board
export const getTasks = createAsyncThunk("tasks/getTasks", async () => {
  return null;
});
export const addCardData = createAsyncThunk(
  "tasks/addCardData",
  async (card) => {
    return card;
  }
);
export const updateCardData = createAsyncThunk(
  "tasks/updateCardData",
  async (card) => {
    return card;
  }
);
export const deleteKanban = createAsyncThunk(
  "tasks/deleteKanban",
  async (card) => {
    return card;
  }
);
