import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import SearchOption from "../../Components/Common/SearchOption";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";

import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Itens = () => {
  document.title = `Itens | ${process.env.REACT_APP_TITLE}`;

  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [itens, setItens] = useState([]);
  const [item, setItem] = useState(null);
  const [medidas, setMedidas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [marca, setMarca] = useState("0");
  const [categorias, setCategorias] = useState([]);
  const [categoria, setCategoria] = useState("0");

  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeItens, setTotalDeItens] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalNovo, setModalNovo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalEditar = useCallback(
    (seg) => {
      setItem(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalApagar = useCallback(
    (seg) => {
      setItem(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const fetchMarcasCategorias = useCallback(async () => {
    let response = await apiClient.get("/marcas");
    if (response.sucesso) {
      setMarcas(response.dados);
      response = await apiClient.get("/categorias");
      if (response.sucesso) {
        setCategorias(response.dados);
        response = await apiClient.get("/medidas");
        if (response.sucesso) {
          setMedidas(response.dados);
        } else {
          console.error(response.mensagem);
          if (!response.mensagem.includes(" 401"))
            if (response.mensagem !== "") toast.error(response.mensagem);
        }
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchItens = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca, marca, categoria) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        marca_id: marca,
        categoria_id: categoria,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/itens/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setItens(dados.data);
        setTotalDeItens(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );
  const limparFiltros = useCallback(() => {
    setMarca("0");
    setCategoria("0");
    setPaginaAtual(1);
    setLoadTable(true);
    setLoading(true);
  }, []);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async (
      itensPorPagina,
      paginaAtual,
      termoBusca,
      marca,
      categoria
    ) => {
      setLoadTable(true);
      if (refresh) {
        await refreshUser();
        await fetchMarcasCategorias();
        setRefresh(false);
      }
      await fetchItens(
        itensPorPagina,
        paginaAtual,
        termoBusca,
        marca,
        categoria
      );
    };

    fetchData(itensPorPagina, paginaAtual, termoBusca, marca, categoria);
    setLoadTable(false);
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    termoBusca,
    marca,
    categoria,
    refresh,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cadastro de Itens" pageTitle="Estoque" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Itens por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={3} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={3}>
                <label className="form-label">Marca</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setMarca(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={marca}
                >
                  <option value="0">Todas</option>
                  {marcas.map((marca, index) => (
                    <option key={index} value={marca.id}>
                      {marca.nome}
                    </option>
                  ))}
                </select>
              </Col>
              <Col xl={3}>
                <label className="form-label">Categoria</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setCategoria(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={categoria}
                >
                  <option value="0">Todas</option>
                  {categorias.map((categoria, index) => (
                    <option key={index} value={categoria.id}>
                      {categoria.nome}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Itens</h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-soft-secondary"
                      onClick={() => toggleModalNovo()}
                      disabled={loading}
                    >
                      Novo Item
                    </button>
                    {(item !== "0" || marca !== "0") && (
                      <button
                        type="button"
                        className="btn btn-soft-danger ms-2"
                        onClick={() => limparFiltros()}
                      >
                        Limpar Filtros
                      </button>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Nome</th>
                              <th scope="col">Marca</th>
                              <th scope="col">Categoria</th>
                              <th scope="col">Medida</th>
                              <th scope="col">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {itens.length !== 0 &&
                              itens.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{item.nome}</b>
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">{item.marca?.nome}</p>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        {item.categoria?.nome}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        {item.medida?.nome}
                                      </p>
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Editar"
                                        onClick={() => toggleModalEditar(item)}
                                      >
                                        <FeatherIcon
                                          icon="edit"
                                          width="14"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        title="Apagar"
                                        onClick={() => {
                                          toggleModalApagar(item);
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="14"
                                        />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && itens.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum item encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeItens
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeItens
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDeItens}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeItens}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}

                  <ModalNovo
                    modal={modalNovo}
                    setModal={setModalNovo}
                    setRefresh={setRefresh}
                    marcas={marcas}
                    medidas={medidas}
                    categorias={categorias}
                  />
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    item={item}
                    setRefresh={setRefresh}
                    marcas={marcas}
                    medidas={medidas}
                    categorias={categorias}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    item={item}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Itens;
