import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, expediente }) => {
  const [loading, setLoading] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [dias, setDias] = useState([]);
  const [horaInicio, setHoraInicio] = useState("00");
  const [minutoInicio, setMinutoInicio] = useState("00");
  const [horaFim, setHoraFim] = useState("00");
  const [minutoFim, setMinutoFim] = useState("00");

  const toggle = () => {
    setDescricao("");
    setDias([]);
    setHoraInicio("00");
    setMinutoInicio("00");
    setHoraFim("00");
    setMinutoFim("00");
    
    setModal(!modal);
  };

  const removeDias = (dia) => {
    const newDias = dias.filter((d) => d !== dia);
    setDias(newDias);
  };

  const addDias = (dia) => {
    const newDias = [...dias, dia];
    setDias(newDias);
  };

  const editar = async (expediente) => {
    setLoading(true);

    const data = {
      descricao,
      hora_inicio: `${horaInicio}:${minutoInicio}`,
      hora_fim: `${horaFim}:${minutoFim}`,
      dias_semana: JSON.stringify(dias),
    };

    const response = await apiClient.put(`expedientes/${expediente?.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      setDescricao(expediente.descricao);
      setHoraInicio(expediente.hora_inicio.split(":")[0]);
      setMinutoInicio(expediente.hora_inicio.split(":")[1]);
      setHoraFim(expediente.hora_fim.split(":")[0]);
      setMinutoFim(expediente.hora_fim.split(":")[1]);
      setDias(JSON.parse(expediente.dias_semana));
    }
    if(expediente) getData();
  }, [expediente]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Expediente {expediente?.descricao}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="descricao" className="form-label">
                  Descrição <span className="text-danger">*</span>
                </Label>
                <Input
                  id="descricao"
                  className="form-control"
                  type="text"
                  placeholder="Descrição"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <Row>
                <Col md="12">
                  <Input
                    id="segunda"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("segunda");
                      } else {
                        removeDias("segunda");
                      }
                    }}
                    checked={dias.includes("segunda")}
                  />
                  <Label htmlFor="segunda">&nbsp;Segunda</Label>
                </Col>
                <Col md="12">
                  <Input
                    id="terca"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("terca");
                      } else {
                        removeDias("terca");
                      }
                    }}
                    checked={dias.includes("terca")}
                  />
                  <Label htmlFor="terca">&nbsp;Terça</Label>
                </Col>
                <Col md="12">
                  <Input
                    id="quarta"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("quarta");
                      } else {
                        removeDias("quarta");
                      }
                    }}
                    checked={dias.includes("quarta")}
                  />
                  <Label htmlFor="quarta">&nbsp;Quarta</Label>
                </Col>
                <Col md="12">
                  <Input
                    id="quinta"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("quinta");
                      } else {
                        removeDias("quinta");
                      }
                    }}
                    checked={dias.includes("quinta")}
                  />
                  <Label htmlFor="quinta">&nbsp;Quinta</Label>
                </Col>
              </Row>
            </Col>

            <Col md="6">
              <Row>
                <Col md="12">
                  <Input
                    id="sexta"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("sexta");
                      } else {
                        removeDias("sexta");
                      }
                    }}
                    checked={dias.includes("sexta")}
                  />
                  <Label htmlFor="sexta">&nbsp;Sexta</Label>
                </Col>
                <Col md="12">
                  <Input
                    id="sabado"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("sabado");
                      } else {
                        removeDias("sabado");
                      }
                    }}
                    checked={dias.includes("sabado")}
                  />
                  <Label htmlFor="sabado">&nbsp;Sábado</Label>
                </Col>
                <Col md="12">
                  <Input
                    id="domingo"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        addDias("domingo");
                      } else {
                        removeDias("domingo");
                      }
                    }}
                    checked={dias.includes("domingo")}
                  />
                  <Label htmlFor="domingo">&nbsp;Domingo</Label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="observacao" className="form-label">
                  Horário de Início <span className="text-danger">*</span>
                </Label>
                <Row>
                  <Col md="6">
                    <Input
                      type="select"
                      id="horaInicio"
                      value={horaInicio}
                      onChange={(e) => setHoraInicio(e.target.value)}
                    >
                      <option value="00">00</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                    </Input>
                  </Col>
                  <Col md="6">
                    <Input
                      type="select"
                      id="minutoInicio"
                      value={minutoInicio}
                      onChange={(e) => setMinutoInicio(e.target.value)}
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </Input>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="observacao" className="form-label">
                  Horário de Fim <span className="text-danger">*</span>
                </Label>
                <Row>
                  <Col md="6">
                    <Input
                      type="select"
                      id="horaFim"
                      value={horaFim}
                      onChange={(e) => setHoraFim(e.target.value)}
                    >
                      <option value="00">00</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                    </Input>
                  </Col>
                  <Col md="6">
                    <Input
                      type="select"
                      id="minutoFim"
                      value={minutoFim}
                      onChange={(e) => setMinutoFim(e.target.value)}
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </Input>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => editar(expediente)}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
