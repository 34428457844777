import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { checkLastLogin, nomeMask } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, filial }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [segmentos, setSegmentos] = useState([]);
  const [segmento, setSegmento] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [cep, setCep] = useState("");
  const [observacao, setObservacao] = useState("");

  const handleCep = async (cepPesquisa) => {
    const _cep = cepPesquisa.replace(/[^0-9]/g, "");
    setCep(_cep);

    if (_cep.length !== 8) return;
    try {
      const response = await apiClient.get(`cep/${_cep}`);
      const dadosRetorno = response.dados;

      setEndereco(dadosRetorno.logradouro);
      setBairro(dadosRetorno.bairro);
      setCidade(`${dadosRetorno.localidade} - ${dadosRetorno.uf}`);
    } catch (erro) {
      console.error(erro);
      toast.error("CEP não encontrado");
    }
  };

  const toggle = () => {
    setNome("");
    setCnpj("");
    setEmail("");
    setTelefone("");
    setSegmentos([]);
    setSegmento("");
    setCep("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setComplemento("");
    setCidade("");
    setObservacao("");

    setModal(!modal);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const editar = async () => {
    setLoading(true);

    const data = {
      nome: nomeMask(nome),
      cnpj,
      email,
      telefone,
      segmento,
      endereco,
      cep,
      numero,
      bairro,
      cidade,
      complemento,
      observacao,
    };

    const response = await apiClient.put(`/filiais/${filial.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      setNome("");
      setCnpj("");
      setEmail("");
      setTelefone("");
      setSegmentos([]);
      setSegmento("");
      setCep("");
      setEndereco("");
      setNumero("");
      setBairro("");
      setComplemento("");
      setCidade("");
      setObservacao("");

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async (filial) => {
    setLoading(true);

    const response = await apiClient.get("/segmentos");
    if (response.sucesso) {
      setSegmentos(response.dados);
      setNome(filial.nome);
      setCnpj(filial.cnpj);
      setEmail(filial.email);
      setTelefone(filial.telefone);
      setSegmento(filial.segmento);
      setCep(filial.cep);
      setEndereco(filial.endereco);
      setNumero(filial.numero);
      setBairro(filial.bairro);
      setComplemento(filial.complemento);
      setCidade(filial.cidade);
      setObservacao(filial.observacao || "");
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetch = async (filial) => {
      await fetchData(filial);
    };
    if (modal && filial) {
      fetch(filial);
    }
  }, [modal, fetchData]);
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar {filial?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="cnpj" className="form-label">
                  CNPJ
                </Label>
                <InputMask
                  mask="99.999.999/9999-99"
                  placeholder="99.999.999/9999-99"
                  value={cnpj || ""}
                  onChange={(e) => setCnpj(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  value={telefone || ""}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="segmento" className="form-label">
                  Segmento <span className="text-danger">*</span>
                </Label>
                <Input
                  id="segmento"
                  className="form-control"
                  type="select"
                  value={segmento}
                  onChange={(e) => setSegmento(e.target.value)}
                >
                  <option value="">Selecione</option>
                  {segmentos.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="cep" className="form-label">
                  CEP
                </Label>
                <InputMask
                  mask="99999-999"
                  placeholder="00000-000"
                  value={cep || ""}
                  onBlur={(e) => handleCep(e.target.value)}
                  onChange={(e) => setCep(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="endereco" className="form-label">
                  Endereço
                </Label>
                <Input
                  id="endereco"
                  className="form-control"
                  type="text"
                  placeholder="Endereço"
                  value={endereco}
                  onChange={(e) => setEndereco(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="numero" className="form-label">
                  Número
                </Label>
                <Input
                  id="numero"
                  className="form-control"
                  type="text"
                  placeholder="Número"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value.replace(/\D/g, ""))}
                  maxLength={8}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="bairro" className="form-label">
                  Bairro
                </Label>
                <Input
                  id="bairro"
                  className="form-control"
                  type="text"
                  placeholder="Bairro"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="cidade" className="form-label">
                  Cidade
                </Label>
                <Input
                  id="cidade"
                  className="form-control"
                  type="text"
                  placeholder="Cidade"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="12" className="mt-3">
              <div>
                <Label htmlFor="complemento" className="form-label">
                  Complemento
                </Label>
                <Input
                  id="complemento"
                  className="form-control"
                  type="text"
                  placeholder="Complemento"
                  value={complemento || ""}
                  onChange={(e) => setComplemento(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="observacao" className="form-label">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                  maxLength={1000}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar()}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
