import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEvents = createAsyncThunk("calendar/getEvents", async () => {
  return null;
});

export const addNewEvent = createAsyncThunk("calendar/addNewEvent", async (event) => {
  return event;
});

export const updateEvent = createAsyncThunk("calendar/updateEvent", async (event) => {
  return event;
});

export const deleteEvent = createAsyncThunk("calendar/deleteEvent", async (event) => {
  return event;
});

export const getCategories = createAsyncThunk("calendar/getCategories", async () => {
  return null;
})

export const getUpCommingEvent = createAsyncThunk("calendar/getUpCommingEvent", async () => {
  return null;
})

export const resetCalendar = createAsyncThunk("calendar/resetCalendar", async () => {
  return null;
})