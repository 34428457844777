import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTeamData = createAsyncThunk("team/getTeamData", async () => {
    return null;
});

export const addTeamData = createAsyncThunk("team/addTeamData", async (team) => {
    return team;
});

export const updateTeamData = createAsyncThunk("team/updateTeamData", async (project) => {
    return project;
});

export const deleteTeamData = createAsyncThunk("team/deleteTeamData", async (project) => {
    return project;
});