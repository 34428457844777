import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import InputMask from "react-input-mask";
import { Link, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatEmail,
  converterData,
  formatCurrency,
  nomeMask,
} from "../../Components/FunctionsRepository";

import ModalVerCarro from "./ModalVerCarro";
import ModalEditarPlanos from "./ModalEditarPlanos";
import ModalTransferirVeiculo from "./ModalTransferirVeiculo";
import ModalApagarVeiculo from "./ModalApagarVeiculo";
import ModalNovoCarro from "./ModalNovoCarro";
import ModalAdicionarPacotes from "./ModalAdicionarPacotes";

import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const EditarCliente = () => {
  document.title = `Editar Cliente | ${process.env.REACT_APP_TITLE}`;

  const { cliente_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [cliente, setCliente] = useState(null);
  const [veiculo, setVeiculo] = useState(null);
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [perfil, setPerfil] = useState("fisica");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [cep, setCep] = useState("");
  const [observacao, setObservacao] = useState("");

  const [modalEditarPlanos, setModalEditarPlanos] = useState(false);
  const [modalVerCarro, setModalVerCarro] = useState(false);
  const [modalTransferirVeiculo, setModalTransferirVeiculo] = useState(false);
  const [modalApagarVeiculo, setModalApagarVeiculo] = useState(false);
  const [modalNovoCarro, setModalNovoCarro] = useState(false);
  const [modalAdicionarPacotes, setModalAdicionarPacotes] = useState(false);

  const toggleModalEditarPlanos = useCallback(() => {
    setModalEditarPlanos(!modalEditarPlanos);
  }, []);

  const toggleModalVerCarro = useCallback((veiculo) => {
    setVeiculo(veiculo);
    setModalVerCarro(!modalVerCarro);
  }, []);

  const toggleModalTransferirVeiculo = useCallback((veiculo) => {
    setVeiculo(veiculo);
    setModalTransferirVeiculo(!modalTransferirVeiculo);
  }, []);

  const toggleModalApagarVeiculo = useCallback((veiculo) => {
    setVeiculo(veiculo);
    setModalApagarVeiculo(!modalApagarVeiculo);
  }, []);

  const toggleModalNovoCarro = useCallback(() => {
    setModalNovoCarro(!modalNovoCarro);
  }, []);

  const toggleModalAdicionarPacotes = useCallback(() => {
    setModalAdicionarPacotes(!modalAdicionarPacotes);
  }, []);

  const excluir = async (servico) => {
    setLoading(true);
    const response = await apiClient.delete(`/clientes/servico/${servico.id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const handleCep = async (cepPesquisa) => {
    const _cep = cepPesquisa.replace(/[^0-9]/g, "");
    setCep(_cep);

    if (_cep.length !== 8) return;
    try {
      const response = await apiClient.get(`cep/${_cep}`);
      const dadosRetorno = response.dados;

      setEndereco(dadosRetorno.logradouro);
      setBairro(dadosRetorno.bairro);
      setCidade(`${dadosRetorno.localidade} - ${dadosRetorno.uf}`);
    } catch (erro) {
      console.error(erro);
      toast.error("CEP não encontrado");
    }
  };

  const editar = async (cliente) => {
    setLoading(true);

    const data = {
      nome: nomeMask(nome),
      cpf_cnpj: perfil === "fisica" ? cpf : cnpj,
      email,
      telefone,
      perfil,
      endereco,
      cep,
      numero,
      bairro,
      cidade,
      complemento,
      observacao,
    };

    const response = await apiClient.put(`clientes/${cliente.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setLoading(true);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const setClienteData = async (cliente) => {
    setNome(cliente.nome);
    setPerfil(cliente.perfil);
    setEmail(cliente.email);
    setTelefone(cliente.telefone);
    setEndereco(cliente.endereco);
    setNumero(cliente.numero);
    setBairro(cliente.bairro);
    setComplemento(cliente.complemento);
    setCidade(cliente.cidade);
    setCep(cliente.cep);
    setObservacao(cliente.observacao);
    if (cliente.perfil === "fisica") setCpf(cliente.cpf_cnpj);
    else setCnpj(cliente.cpf_cnpj);
  };

  const fetchCliente = useCallback(async (id) => {
    const response = await apiClient.get(`/clientes/${id}`);
    if (response.sucesso) {
      setCliente(response.dados);
      setClienteData(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCliente(cliente_id);
      setLoading(false);
    };

    fetchData();
  }, [loading]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Editar Cliente" pageTitle="Clientes" />
          <Row>
            <Col xs="12 mb-3">
              <Link
                to="/clientes"
                className="btn btn-link link-primary fw-medium"
              >
                <i className="ri-arrow-left-line me-2 align-middle"></i>
                Voltar
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {cliente?.nome}
                  </h4>
                  {cliente?.telefone && (
                    <div className="flex-shrink-0">
                      <Link
                        to={`https://wa.me/55${(
                          cliente?.telefone || ""
                        ).replace(/\D/g, "")}?text=`}
                        target="_blank"
                        className="btn btn-sm btn-success"
                      >
                        <FeatherIcon icon="message-square" size={16} /> Falar
                        com Cliente via WhatsApp
                      </Link>
                    </div>
                  )}
                </CardHeader>
                <CardBody>
                  <div className="mt-4">
                    <Row className="mt-5">
                      <Col md="6">
                        <div>
                          <Label htmlFor="nome" className="form-label">
                            Nome <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="nome"
                            className="form-control"
                            type="text"
                            placeholder="Nome"
                            value={nome || ""}
                            onChange={(e) => setNome(e.target.value)}
                            maxLength={150}
                          />
                        </div>
                      </Col>
                      {perfil === "fisica" ? (
                        <Col md="6">
                          <div>
                            <Label htmlFor="cpf" className="form-label">
                              CPF
                            </Label>
                            <InputMask
                              mask="999.999.999-99"
                              placeholder="999.999.999-99"
                              value={cpf || ""}
                              onChange={(e) => setCpf(e.target.value)}
                            >
                              {(inputProps) => (
                                <Input
                                  type="text"
                                  className="form-control"
                                  {...inputProps}
                                />
                              )}
                            </InputMask>
                          </div>
                        </Col>
                      ) : (
                        <Col md="6">
                          <div>
                            <Label htmlFor="cnpj" className="form-label">
                              CNPJ
                            </Label>
                            <InputMask
                              mask="99.999.999/9999-99"
                              placeholder="99.999.999/9999-99"
                              value={cnpj || ""}
                              onChange={(e) => setCnpj(e.target.value)}
                            >
                              {(inputProps) => (
                                <Input
                                  type="text"
                                  className="form-control"
                                  {...inputProps}
                                />
                              )}
                            </InputMask>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <div>
                          <Label htmlFor="email" className="form-label">
                            E-mail
                          </Label>
                          <Input
                            id="email"
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            value={email || ""}
                            onChange={(e) =>
                              setEmail(formatEmail(e.target.value))
                            }
                            maxLength={150}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <Label htmlFor="telefone" className="form-label">
                            Telefone <span className="text-danger">*</span>
                          </Label>
                          <InputMask
                            mask="(99) 99999-9999"
                            placeholder="(11) 99999-9999"
                            value={telefone || ""}
                            onChange={(e) => setTelefone(e.target.value)}
                          >
                            {(inputProps) => (
                              <Input
                                type="text"
                                className="form-control"
                                {...inputProps}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <div>
                          <Label htmlFor="perfil" className="form-label">
                            Perfil <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="perfil"
                            className="form-control"
                            type="select"
                            value={perfil || "fisica"}
                            onChange={(e) => setPerfil(e.target.value)}
                          >
                            <option value="fisica">Pessoa Física</option>
                            <option value="juridica">Pessoa Jurídica</option>
                          </Input>
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <Label htmlFor="cep" className="form-label">
                            CEP
                          </Label>
                          <InputMask
                            mask="99999-999"
                            placeholder="00000-000"
                            value={cep || ""}
                            onChange={(e) => setCep(e.target.value)}
                            onBlur={(e) => handleCep(e.target.value)}
                          >
                            {(inputProps) => (
                              <Input
                                type="text"
                                className="form-control"
                                {...inputProps}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <div>
                          <Label htmlFor="endereco" className="form-label">
                            Endereço
                          </Label>
                          <Input
                            id="endereco"
                            className="form-control"
                            type="text"
                            placeholder="Endereço"
                            value={endereco || ""}
                            onChange={(e) => setEndereco(e.target.value)}
                            maxLength={255}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <Label htmlFor="numero" className="form-label">
                            Número
                          </Label>
                          <Input
                            id="numero"
                            className="form-control"
                            type="text"
                            placeholder="Número"
                            value={numero || ""}
                            onChange={(e) =>
                              setNumero(e.target.value.replace(/\D/g, ""))
                            }
                            maxLength={8}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <div>
                          <Label htmlFor="bairro" className="form-label">
                            Bairro
                          </Label>
                          <Input
                            id="bairro"
                            className="form-control"
                            type="text"
                            placeholder="Bairro"
                            value={bairro || ""}
                            onChange={(e) => setBairro(e.target.value)}
                            maxLength={150}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <Label htmlFor="cidade" className="form-label">
                            Cidade
                          </Label>
                          <Input
                            id="cidade"
                            className="form-control"
                            type="text"
                            placeholder="Cidade"
                            value={cidade || ""}
                            onChange={(e) => setCidade(e.target.value)}
                            maxLength={150}
                          />
                        </div>
                      </Col>
                      <Col md="12" className="mt-3">
                        <div>
                          <Label htmlFor="complemento" className="form-label">
                            Complemento
                          </Label>
                          <Input
                            id="complemento"
                            className="form-control"
                            type="text"
                            placeholder="Complemento"
                            value={complemento || ""}
                            onChange={(e) => setComplemento(e.target.value)}
                            maxLength={255}
                          />
                        </div>
                      </Col>
                      <Col md="12 mt-2">
                        <div>
                          <Label htmlFor="observacao" className="form-label">
                            Observações
                          </Label>
                          <Input
                            id="observacao"
                            className="form-control"
                            type="textarea"
                            placeholder="Observações"
                            value={observacao || ""}
                            onChange={(e) => setObservacao(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="hstack gap-2 justify-content-end mt-5">
                      <button
                        className="btn btn-success"
                        onClick={() => editar(cliente)}
                        disabled={loading}
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Veículos</h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-primary"
                      onClick={() => toggleModalNovoCarro()}
                    >
                      Adicionar Veículo
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row className="mt-2">
                    <Col md="12">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Placa</th>
                            <th>Marca</th>
                            <th>Modelo</th>
                            <th>Ano</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cliente?.carros?.map((veiculo) => (
                            <tr key={veiculo.id}>
                              <td>{veiculo.placa}</td>
                              <td>{veiculo.marca}</td>
                              <td>{veiculo.modelo}</td>
                              <td>{veiculo.ano}</td>
                              <td>
                                <Link
                                  to="#"
                                  onClick={() => toggleModalVerCarro(veiculo)}
                                  className="text-primary"
                                  title="Ver detalhes do veículo"
                                >
                                  <FeatherIcon icon="eye" size={14} />
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    toggleModalTransferirVeiculo(veiculo)
                                  }
                                  className="text-secondary ms-2"
                                  title="Transferir veículo para outro cliente"
                                >
                                  <FeatherIcon icon="repeat" size={14} />
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    toggleModalApagarVeiculo(veiculo)
                                  }
                                  className="text-danger ms-2"
                                  title="Remover veículo do cliente"
                                >
                                  <FeatherIcon icon="trash" size={14} />
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Planos</h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-primary"
                      onClick={() => toggleModalEditarPlanos()}
                    >
                      Editar Planos
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mt-4">
                    {cliente?.planos?.map((plano) => (
                      <div
                        key={plano.id}
                        className="d-flex justify-content-between mt-2"
                      >
                        <div>
                          <h5 className="mb-0 text-info">{plano.nome}</h5>
                          <p className="mb-0">
                            <b>Ativo até:</b>{" "}
                            {converterData(plano?.expiracao, false)}
                          </p>
                        </div>
                        <div>{formatCurrency(plano.preco)}</div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
              <Card className="mt-3">
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Serviços Ativos
                  </h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-primary"
                      onClick={() => toggleModalAdicionarPacotes()}
                    >
                      Adicionar Pacotes de Serviços
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mt-4">
                    {cliente?.servicos?.map((servico) => (
                      <div
                        key={servico.id}
                        className="d-flex justify-content-between mt-2"
                      >
                        <div>
                          <h5 className="mb-0 text-primary">
                            {servico.servico?.servico}
                          </h5>
                          <p className="mb-0">
                            <b>Ativo até:</b>{" "}
                            {servico.plano_id > 0
                              ? converterData(servico?.expiracao, false)
                              : "Sem expiração"}
                          </p>
                        </div>
                        <div>
                          {servico.plano_id > 0 ? (
                            <button
                              disabled
                              className="btn btn-sm btn-secondary"
                            >
                              Plano
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                excluir(servico);
                              }}
                              disabled={loading}
                            >
                              Remover
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalEditarPlanos
            modal={modalEditarPlanos}
            setModal={setModalEditarPlanos}
            cliente={cliente}
            setCliente={setCliente}
            setRefresh={setLoading}
          />
          <ModalVerCarro
            modal={modalVerCarro}
            setModal={setModalVerCarro}
            cliente={cliente}
            veiculo={veiculo}
          />

          <ModalTransferirVeiculo
            modal={modalTransferirVeiculo}
            setModal={setModalTransferirVeiculo}
            cliente={cliente}
            veiculo={veiculo}
          />

          <ModalNovoCarro
            modal={modalNovoCarro}
            setModal={setModalNovoCarro}
            cliente={cliente}
            setRefresh={setLoading}
          />

          <ModalAdicionarPacotes
            modal={modalAdicionarPacotes}
            setModal={setModalAdicionarPacotes}
            cliente={cliente}
            setRefresh={setLoading}
          />

          <ModalApagarVeiculo
            modal={modalApagarVeiculo}
            setModal={setModalApagarVeiculo}
            cliente={cliente}
            veiculo={veiculo}
            setRefresh={setLoading}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default EditarCliente;
