import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTransationList = createAsyncThunk("crypto/getTransationList", async () => {
    return null;
});

export const getOrderList = createAsyncThunk("crypto/getOrderList", async () => {
    return null;
});

