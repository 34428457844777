import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBalanceChartsData = createAsyncThunk("dashboardCrm/getBalanceChartsData", async (data) => {
  return data;
});

export const getDialChartsData = createAsyncThunk("dashboardCrm/getDialChartsData", async (data) => {
  return data;
});

export const getSalesChartsData = createAsyncThunk("dashboardCrm/getSalesChartsData", async (data) => {
  return data;
});