import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

export const getInvoices = createAsyncThunk("invoice/getInvoices", async () => {
  return null;
});

export const addNewInvoice = createAsyncThunk("invoice/addNewInvoice", async (invoice) => {
  return invoice;
});

export const updateInvoice = createAsyncThunk("invoice/updateInvoice", async (invoice) => {
  return invoice;
});

export const deleteInvoice = createAsyncThunk("invoice/deleteInvoice", async (invoice) => {
  return invoice;
});