import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
  converterData,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
const apiClient = new APIClient();

const ModalEditarPlanos = ({
  modal,
  setModal,
  setRefresh,
  cliente,
  setCliente,
}) => {
  const [loading, setLoading] = useState(true);

  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [filial, setFilial] = useState({});

  const [plano, setPlano] = useState({});
  const [optionsPlanos, setOptionsPlanos] = useState([]);
  const [planosSelecionados, setPlanosSelecionados] = useState([]);
  const [optionsFormasPagamento, setOptionsFormasPagamento] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState({});
  const [meses, setMeses] = useState(1);
  const [valorTotal, setValorTotal] = useState("");
  const [desconto, setDesconto] = useState("");
  const [statusPlano, setStatusPlano] = useState("novo");

  const [novo, setNovo] = useState(false);
  const [renovar, setRenovar] = useState(false);

  const toggle = () => {
    setPlano({});
    setPlanosSelecionados([]);
    setFormaPagamento({});
    setMeses(1);
    setValorTotal("");
    setDesconto("");
    setNovo(false);
    setRenovar(false);

    setModal(!modal);
  };

  const toggleNovo = (plano) => {
    setMeses(1);
    setFormaPagamento({});
    setValorTotal(plano.preco);
    setDesconto("");
    setRenovar(false);
    setNovo(!novo);
  };

  const toggleRenovar = (plano) => {
    setMeses(1);
    setFormaPagamento({});
    setValorTotal(plano?.preco || "");
    setDesconto("");
    setNovo(false);
    setPlano(plano);
    setRenovar(!renovar);
  };

  const setarPlano = (plano) => {
    calcularTotal(
      plano?.preco,
      parseFloat(desconto === "" ? 0 : desconto),
      parseInt(meses)
    );
    setRenovar(false);
    setPlano(plano);
  };

  const calcularTotal = (preco, desconto, meses) => {
    const total = parseFloat(preco) * parseFloat(meses);
    const totalDescontado = total * (1 - parseFloat(desconto) / 100);
    setValorTotal(totalDescontado);
  };

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (userData.filiais.length > 0 && !filial?.value && userData.role !== "super-admin") {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
      setRefresh(true);
    }
  }, []);

  const fetchCliente = async (cliente) => {
    const response = await apiClient.get(`/clientes/${cliente.id}`);
    if (response.sucesso) {
      setCliente(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const fetchFormasPagamento = useCallback(async () => {
    const response = await apiClient.get("/formas-pagamento");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFormasPagamento(
        dados.map((item) => ({
          label: `${item?.nome}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchPlanos = useCallback(async (cliente) => {
    const response = await apiClient.get("/planos");
    if (response.sucesso) {
      const dados = response.dados;
      const mapDados = dados.map((plano) => ({
        label: `${plano?.nome} (${formatCurrency(plano?.preco)})`,
        preco: `${plano?.preco}`,
        value: `${plano?.id}`,
      }));
      setOptionsPlanos(mapDados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    const servicos = cliente?.servicos || [];

    const planos =
      cliente?.planos?.map((plano) => {
        const expiracao = servicos.find(
          (servico) => servico?.plano_id === plano?.id
        )?.expiracao;

        return {
          label: `${plano?.nome} (${formatCurrency(plano?.preco)})`,
          nome: plano?.nome,
          preco: plano?.preco,
          expiracao: expiracao,
          value: plano?.id,
        };
      }) || [];

    setPlanosSelecionados(planos);
  }, []);

  const renovarPlano = async (cliente, plano) => {
    setLoading(true);

    const data = {
      cliente_id: cliente.id,
      filial_id: filial.value,
      forma_pagamento_id: formaPagamento.value,
      meses,
      valor_total: valorTotal,
    };
    const response = await apiClient.put(
      `clientes/planos/${plano.value}/renovar`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      setDesconto("");
      setValorTotal("");
      setMeses(1);
      setFormaPagamento({});
      setPlano({});
      setRenovar(false);

      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      await fetchCliente(cliente);
      await fetchPlanos(cliente);
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const adicionarPlano = async (cliente, plano) => {
    setLoading(true);

    const data = {
      cliente_id: cliente.id,
      filial_id: filial.value,
      meses,
      forma_pagamento_id: formaPagamento.value,
      status: statusPlano,
      valor_total: valorTotal,
    };

    const response = await apiClient.put(
      `/clientes/planos/${plano.value}/adicionar`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      setPlanosSelecionados([...planosSelecionados, plano]);
      setPlano({});
      toggleNovo(plano);

      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      await fetchCliente(cliente);
      await fetchPlanos(cliente);
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const removerPlano = async (cliente, plano) => {
    setLoading(true);

    const response = await apiClient.delete(
      `/clientes/${cliente.id}/planos/${plano.value}/remover`
    );
    if (response.sucesso) {
      toast.success(response.mensagem);

      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      await fetchCliente(cliente);
      await fetchPlanos(cliente);
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async (cliente) => {
      await fetchFormasPagamento();
      await fetchPlanos(cliente);
      await fetchFiliais();
      setLoading(false);
    };
    if (modal && cliente) fetchData(cliente);
  }, [cliente, modal, fetchPlanos]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Alterar Planos
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-2">
          {!renovar && (
            <Row className="mt-3">
              <Col md="6">
                <Label htmlFor="planos" className="form-label">
                  Planos
                </Label>
                <Select
                  value={plano}
                  onChange={(sortBy) => {
                    setarPlano(sortBy);
                  }}
                  options={optionsPlanos}
                  id="planos"
                  placeholder={loading ? "Carregando..." : "Selecione o plano"}
                  disabled={loading}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #ced4da",
                      color: "#aaa",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#aaa",
                    }),
                  }}
                />
              </Col>
              <Col md="6">
                <button
                  className="btn btn-success mt-4"
                  onClick={() => toggleNovo(plano)}
                  disabled={loading || !plano?.value}
                >
                  Adicionar Novo Plano
                </button>
              </Col>
            </Row>
          )}
          {novo && (
            <>
              <h5 className="mt-3">Adicionar Plano</h5>
              <Row className="mt-2">
                <Col md="12">
                  <Label for="filial" className="form-label">
                    Filial
                  </Label>
                  <Select
                    value={filial}
                    onChange={(sortBy) => {
                      setFilial(sortBy);
                    }}
                    options={optionsFiliais}
                    id="filial"
                    placeholder={
                      loading ? "Carregando..." : "Selecione a filial"
                    }
                    disabled={loading}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #ced4da",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="6">
                  <Label for="statusPlano" className="form-label">
                    Status
                  </Label>
                  <Input
                    type="select"
                    name="statusPlano"
                    id="statusPlano"
                    value={statusPlano}
                    onChange={(e) => {
                      setStatusPlano(e.target.value);
                    }}
                  >
                    <option value="novo">Novo</option>
                    <option value="pago">Pago</option>
                  </Input>
                </Col>
                <Col md="6">
                  <Label for="meses" className="form-label">
                    Meses
                  </Label>
                  <Input
                    type="select"
                    name="meses"
                    id="meses"
                    value={meses}
                    onChange={(e) => {
                      setMeses(e.target.value);
                      calcularTotal(
                        plano?.preco,
                        parseFloat(desconto === "" ? 0 : desconto),
                        parseInt(e.target.value)
                      );
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Input>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="6">
                  <Label for="formaPagamento" className="form-label">
                    Forma de Pagamento
                  </Label>
                  <Select
                    value={formaPagamento}
                    onChange={(sortBy) => {
                      setFormaPagamento(sortBy);
                    }}
                    options={optionsFormasPagamento}
                    id="formaPagamento"
                    placeholder={
                      loading
                        ? "Carregando..."
                        : "Selecione a forma de pagamento"
                    }
                    disabled={loading}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #ced4da",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                </Col>
                <Col md="6">
                  <Label for="valorTotal" className="form-label">
                    Valor Total
                  </Label>
                  <NumericFormat
                    id="valorTotal"
                    placeholder="R$ 0,00"
                    className="form-control"
                    value={valorTotal || ""}
                    onValueChange={() => {}}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale={true}
                    allowNegative={false}
                    readOnly
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="12" className="pt-2 d-flex justify-content-end">
                  <button
                    className="btn btn-sm btn-danger mt-4"
                    onClick={() => {
                      setPlano({});
                      toggleNovo(plano);
                    }}
                  >
                    Fechar
                  </button>
                  <button
                    className="btn btn-sm btn-success mt-4 ms-3"
                    onClick={() => adicionarPlano(cliente, plano)}
                    disabled={
                      loading || !plano?.value || !formaPagamento?.value
                    }
                  >
                    Adicionar
                  </button>
                </Col>
              </Row>
            </>
          )}

          {renovar && (
            <>
              <h5 className="mt-3">Renovar Plano</h5>
              <Row className="mt-2">
                <Col md="12">
                  <Label for="filial" className="form-label">
                    Filial
                  </Label>
                  <Select
                    value={filial}
                    onChange={(sortBy) => {
                      setFilial(sortBy);
                    }}
                    options={optionsFiliais}
                    id="filial"
                    placeholder={
                      loading ? "Carregando..." : "Selecione a filial"
                    }
                    disabled={loading}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #ced4da",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="12">
                  <Label for="formaPagamento" className="form-label">
                    Forma de Pagamento
                  </Label>
                  <Select
                    value={formaPagamento}
                    onChange={(sortBy) => {
                      setFormaPagamento(sortBy);
                    }}
                    options={optionsFormasPagamento}
                    id="formaPagamento"
                    placeholder={
                      loading
                        ? "Carregando..."
                        : "Selecione a forma de pagamento"
                    }
                    disabled={loading}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #ced4da",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="6">
                  <Label for="meses" className="form-label">
                    Meses
                  </Label>
                  <Input
                    type="select"
                    name="meses"
                    id="meses"
                    value={meses}
                    onChange={(e) => {
                      setMeses(e.target.value);
                      calcularTotal(
                        plano?.preco,
                        parseFloat(desconto === "" ? 0 : desconto),
                        parseInt(e.target.value)
                      );
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Input>
                </Col>
                <Col md="6">
                  <Label for="valorTotal" className="form-label">
                    Valor Total
                  </Label>
                  <NumericFormat
                    id="valorTotal"
                    placeholder="R$ 0,00"
                    className="form-control"
                    value={valorTotal || ""}
                    onValueChange={() => {}}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale={true}
                    allowNegative={false}
                    readOnly
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="12" className="pt-2 d-flex justify-content-end">
                  <button
                    className="btn btn-sm btn-danger mt-4"
                    onClick={() => {
                      toggleRenovar(plano);
                      setPlano({});
                    }}
                  >
                    Fechar
                  </button>
                  <button
                    className="btn btn-sm btn-success ms-3 mt-4"
                    onClick={() => renovarPlano(cliente, plano)}
                    disabled={
                      loading || !plano?.value || !formaPagamento?.value
                    }
                  >
                    Renovar
                  </button>
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-3 border-top pt-3">
            <Col md="12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Plano</th>
                    <th>Preço Mensal</th>
                    <th>Vencimento</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : (
                    planosSelecionados.map((plano, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              overflowWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {plano.nome}
                          </td>
                          <td>{formatCurrency(plano.preco)}</td>
                          <td>{converterData(plano?.expiracao, false)}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => removerPlano(cliente, plano)}
                            >
                              Remover
                            </button>
                            <button
                              className="btn btn-sm btn-primary ms-2"
                              onClick={() => toggleRenovar(plano)}
                            >
                              Renovar
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-warning fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditarPlanos;
