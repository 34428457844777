import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [observacao, setObservacao] = useState("");

  const toggle = () => {
    setNome("");

    setModal(!modal);
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      nome,
      observacao,
    };

    const response = await apiClient.post("/segmentos", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      setNome("");
      setObservacao("");

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Novo Segmento
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="observacao" className="form-label">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                  maxLength={1000}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
