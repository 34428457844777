import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api_helper";

const Navdata = () => {
  const [userData, setUserData] = useState({});
  const history = useNavigate();
  //state data
  const [isPrincipal, setIsPrincipal] = useState(true);
  const [isConfiguracoes, setIsConfiguracoes] = useState(false);
  const [isUsuarios, setIsUsuarios] = useState(false);
  const [isClientes, setIsClientes] = useState(false);
  const [isOrdemServico, setIsOrdemServico] = useState(false);
  const [isIncidentes, setIsIncidentes] = useState(false);
  const [isFiliais, setIsFiliais] = useState(false);
  const [isFinanceiro, setIsFinanceiro] = useState(false);
  const [isEstoque, setIsEstoque] = useState(false);
  const [isLogs, setIsLogs] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Principal");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    const getUserData = async () => {
      const data = await getLoggedinUser();
      setUserData(data);
    };
    getUserData();
    
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Principal") {
      setIsPrincipal(false);
    }
    if (iscurrentState !== "Configuracoes") {
      setIsConfiguracoes(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
    if (iscurrentState !== "Clientes") {
      setIsClientes(false);
    }
    if (iscurrentState !== "OrdemServico") {
      setIsOrdemServico(false);
    }
    if (iscurrentState !== "Incidentes") {
      setIsIncidentes(false);
    }
    if (iscurrentState !== "Estoque") {
      setIsEstoque(false);
    }
    if (iscurrentState !== "Filiais") {
      setIsFiliais(false);
    }
    if (iscurrentState !== "Financeiro") {
      setIsFinanceiro(false);
    }
    if (iscurrentState !== "Logs") {
      setIsLogs(false);
    }
  }, [
    history,
    iscurrentState,
    isPrincipal,
    isConfiguracoes,
    isUsuarios,
    isClientes,
    isOrdemServico,
    isIncidentes,
    isEstoque,
    isFiliais,
    isFinanceiro,
    isLogs,
  ]);

  const menuItems = [
    {
      label: "Principal",
      isHeader: true,
    },
    (userData?.role === "super-admin" || userData?.role === "admin") && {
      id: "principal",
      label: "Principal",
      icon: "ri-home-line",
      link: "/principal",
      stateVariables: isPrincipal,
      click: function (e) {
        e.preventDefault();
        setIsPrincipal(!isPrincipal);
        setIscurrentState("Principal");
        updateIconSidebar(e);
      },
    },
    {
      id: "clientes",
      label: "Clientes",
      icon: "bx bx-user",
      link: "/clientes",
      stateVariables: isClientes,
      click: function (e) {
        e.preventDefault();
        setIsClientes(!isClientes);
        setIscurrentState("Clientes");
        updateIconSidebar(e);
      },
    },
    {
      id: "ordens-servico",
      label: "Ordens de Serviço",
      icon: "bx bx-wrench",
      link: "/ordens-servico",
      stateVariables: isOrdemServico,
      click: function (e) {
        e.preventDefault();
        setIsOrdemServico(!isOrdemServico);
        setIscurrentState("OrdemServico");
        updateIconSidebar(e);
      },
    },
    // {
    //   id: "incidentes",
    //   label: "Incidentes",
    //   icon: "bx bx-error",
    //   link: "/incidentes",
    //   stateVariables: isIncidentes,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsIncidentes(!isIncidentes);
    //     setIscurrentState("Incidentes");
    //     updateIconSidebar(e);
    //   },
    // },
    {
      label: "Administrativo",
      isHeader: true,
    },
    {
      id: "financeiro",
      label: "Financeiro",
      icon: "bx bx-dollar",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsFinanceiro(!isFinanceiro);
        setIscurrentState("Financeiro");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pacotes-clientes",
          label: "Pagamentos de Pacotes",
          link: "/pacotes-pagamentos",
          parentId: "financeiro",
        },
        {
          id: "planos-clientes",
          label: "Pagamentos de Planos",
          link: "/planos-pagamentos",
          parentId: "financeiro",
        },
      ],
    },
    {
      id: "estoque",
      label: "Estoque",
      icon: "bx bx-box",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsEstoque(!isEstoque);
        setIscurrentState("Estoque");
        updateIconSidebar(e);
      },
      stateVariables: isEstoque,
      subItems: [
        {
          id: "itens",
          label: "Itens",
          link: "/itens",
          parentId: "estoque",
        },
        {
          id: "categorias",
          label: "Categorias",
          link: "/categorias-itens",
          parentId: "estoque",
        },
        {
          id: "marcas",
          label: "Marcas",
          link: "/marcas-itens",
          parentId: "estoque",
        },
        {
          id: "medidas",
          label: "Medidas",
          link: "/medidas-itens",
          parentId: "estoque",
        },
        {
          id: "entrada-saida",
          label: "Entradas / Saídas",
          link: "/entrada-saida-estoque",
          parentId: "estoque",
        },
        {
          id: "inventario",
          label: "Inventário",
          link: "/inventario",
          parentId: "estoque",
        },
      ],
    },
    {
      id: "filiais",
      label: "Filiais",
      icon: "bx bx-building",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsFiliais(!isFiliais);
        setIscurrentState("Filiais");
        updateIconSidebar(e);
      },
      stateVariables: isFiliais,
      subItems: [
        {
          id: "filiais-cadastro",
          label: "Filiais",
          link: "/filiais",
          parentId: "filiais",
        },
        {
          id: "segmentos",
          label: "Segmentos",
          link: "/segmentos",
          parentId: "filiais",
        },
        {
          id: "expedientes",
          label: "Expedientes",
          link: "/expedientes",
          parentId: "filiais",
        },
      ],
    },
    {
      id: "configuracoes",
      label: "Configurações",
      icon: "mdi mdi-application-cog",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsConfiguracoes(!isConfiguracoes);
        setIscurrentState("Configuracoes");
        updateIconSidebar(e);
      },
      stateVariables: isConfiguracoes,
      subItems: [
        {
          id: "fornecedores",
          label: "Fornecedores",
          link: "/fornecedores",
          parentId: "configuracoes",
        },
        {
          id: "servicos",
          label: "Serviços",
          link: "/servicos",
          parentId: "configuracoes",
        },
        {
          id: "planos",
          label: "Planos",
          link: "/planos",
          parentId: "configuracoes",
        },
        {
          id: "pacotes",
          label: "Pacotes",
          link: "/pacotes",
          parentId: "configuracoes",
        },
        {
          id: "formas-pagamento",
          label: "Formas de Pagamento",
          link: "/formas-pagamento",
          parentId: "configuracoes",
        },
      ],
    },
    {
      id: "usuarios",
      label: "Usuários",
      icon: "bx bx-user-circle",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsUsuarios(!isUsuarios);
        setIscurrentState("Usuarios");
        updateIconSidebar(e);
      },
      stateVariables: isUsuarios,
      subItems: [
        {
          id: "usuario-cadastro",
          label: "Usuários",
          link: "/usuarios",
          parentId: "usuarios",
        },
        {
          id: "funcoes",
          label: "Funções",
          link: "/funcoes",
          parentId: "usuarios",
        },
      ],
    },
    {
      id: "logs",
      label: "Logs",
      icon: "bx bx-file",
      link: "/logs",
      stateVariables: isLogs,
      click: function (e) {
        e.preventDefault();
        setIsLogs(!isLogs);
        setIscurrentState("Logs");
        updateIconSidebar(e);
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
