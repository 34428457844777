import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';


export const getTicketsList = createAsyncThunk("tickets/getTicketsList", async () => {
    return null;
});

export const addNewTicket = createAsyncThunk("tickets/addNewTicket", async (ticket) => {
    return ticket;
});

export const updateTicket = createAsyncThunk("tickets/updateTicket", async (ticket) => {
    return ticket;
});

export const deleteTicket = createAsyncThunk("tickets/deleteTicket", async (ticket) => {
    return ticket;
});