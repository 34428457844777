import React from "react";
import {
  Card,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";

const Faturamento = () => {
  var chartDonutBasicColors = getChartColorsArray(
    '["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
  );

  const series = [0, 0, 0, 0, 0];
  var options = {
    labels: ["Dez", "Nov", "Out", "Set", "Ago"],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Faturamento</h4>
            <div className="flex-shrink-0">
              <UncontrolledDropdown
                className="card-header-dropdown"
                direction="start"
              >
                <DropdownToggle
                  tag="a"
                  className="text-reset dropdown-btn"
                  role="button"
                >
                  <span className="text-muted">
                    Relatório<i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem>Exportar Relatório</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardHeader>

          <ReactApexChart
            dir="ltr"
            options={options}
            series={series}
            type="donut"
            height="333"
            className="apex-charts"
          />
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Faturamento;
