import React, { useState, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Progress,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
  nomeMask,
  formatEmail,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalNovo = ({
  modal,
  setModal,
  setRefresh,
  optionsFiliais,
  optionsExpedientes,
  optionsFormasPagamento,
  optionsServicos,
}) => {
  const [loading, setLoading] = useState(false);
  const [etapa, setEtapa] = useState(1);
  const [cliente, setCliente] = useState(null);
  const [veiculo, setVeiculo] = useState(null);
  const [filial, setFilial] = useState({});
  const [expediente, setExpediente] = useState({});
  const [formaPagamento, setFormaPagamento] = useState({});
  const [descricao, setDescricao] = useState("");
  const [dataRealizado, setDataRealizado] = useState("");
  const [total, setTotal] = useState(0);
  const [totalDescontado, setTotalDescontado] = useState(0);
  const [desconto, setDesconto] = useState("");

  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [submodelo, setSubmodelo] = useState("");
  const [situacao, setSituacao] = useState("");
  const [versao, setVersao] = useState("");
  const [cor, setCor] = useState("");
  const [ano, setAno] = useState("");
  const [anoModelo, setAnoModelo] = useState("");
  const [origem, setOrigem] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [uf, setUf] = useState("");
  const [logo, setLogo] = useState("");

  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [perfil, setPerfil] = useState("fisica");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [ufCliente, setUfCliente] = useState("");
  const [cep, setCep] = useState("");
  const [statusOrdem, setStatusOrdem] = useState("novo");

  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [servico, setServico] = useState({});

  const [servicosAdicionais, setServicosAdicionais] = useState([]);
  const [descricaoAdicional, setDescricaoAdicional] = useState("");
  const [valorAdicional, setValorAdicional] = useState("");

  const quantidadeEmPlano = (servicoAdicionado, servicos) => {
    const emPlano = servicos.filter((serv) => {
      return parseInt(serv) === parseInt(servicoAdicionado.value);
    }).length;
    return emPlano;
  };

  const addServicoAdicional = () => {
    if (
      !valorAdicional ||
      valorAdicional === "" ||
      valorAdicional === "0" ||
      parseFloat(valorAdicional) === 0
    ) {
      toast.error("Valor de serviço adicional é obrigatório");
      return;
    }
    if (!descricaoAdicional || descricaoAdicional === "") {
      toast.error("Descrição de serviço adicional é obrigatório");
      return;
    }
    const servicoAdicionado = {
      descricao: descricaoAdicional,
      preco: parseFloat(valorAdicional === "" ? 0 : valorAdicional),
      index: servicosAdicionais.length,
    };

    //adiciona ao total e totalDescontado
    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    const _total = total + servicoAdicionado.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    const servicos = [...servicosAdicionais, servicoAdicionado];
    setServicosAdicionais(servicos);
    setDescricaoAdicional("");
    setValorAdicional("");
  };

  const removeServicoAdicional = (index) => {
    const servicoRemovido = servicosAdicionais[index];
    const servicos = servicosAdicionais.filter((serv, i) => i !== index);

    //remove do total e totalDescontado
    let descontoOS = desconto === "" ? 0 : parseFloat(desconto);
    const _total = total - servicoRemovido.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    setServicosAdicionais(servicos);
  };

  const handleCep = async (cepPesquisa) => {
    const _cep = cepPesquisa.replace(/[^0-9]/g, "");
    setCep(_cep);

    if (_cep.length !== 8) return;
    try {
      const response = await apiClient.get(`cep/${_cep}`);
      const dadosRetorno = response.dados;

      setEndereco(dadosRetorno.logradouro);
      setBairro(dadosRetorno.bairro);
      setCidade(`${dadosRetorno.localidade} - ${dadosRetorno.uf}`);
    } catch (erro) {
      console.error(erro);
      toast.error("CEP não encontrado");
    }
  };

  const formatPlaca = (placa) => {
    setPlaca(placa.replace(/[^a-zA-Z0-9]/g, "").toUpperCase());
  };

  const fetchClienteDadosId = async (id) => {
    setLoading(true);
    const response = await apiClient.get(`/clientes/${id}`);
    if (response.sucesso) {
      const dados = response.dados;
      setCliente(dados);
      setNome(dados.nome);
      setPerfil(dados.perfil);
      setCpf(dados.cpf_cnpj);
      setCnpj(dados.cpf_cnpj);
      setEmail(dados.email);
      setTelefone(dados.telefone);
      setCep(dados.cep);
      setEndereco(dados.endereco);
      setNumero(dados.numero);
      setBairro(dados.bairro);
      setComplemento(dados.complemento);
      const cidadeNome = dados.cidade.split(" - ");
      setCidade(cidadeNome[0]);
      setUfCliente(cidadeNome[1]);
    }
    setLoading(false);
  };

  const fetchClienteDados = async () => {
    setLoading(true);
    const data = {
      telefone,
    };
    const response = await apiClient.post("/clientes/buscar/telefone", data);
    if (response.sucesso) {
      const dados = response.dados;
      setCliente(dados);
      setNome(dados.nome);
      setPerfil(dados.perfil);
      setCpf(dados.cpf_cnpj);
      setCnpj(dados.cpf_cnpj);
      setEmail(dados.email);
      setTelefone(dados.telefone);
      setCep(dados.cep);
      setEndereco(dados.endereco);
      setNumero(dados.numero);
      setBairro(dados.bairro);
      setComplemento(dados.complemento);
      const cidadeNome = dados.cidade.split(" - ");
      setCidade(cidadeNome[0]);
      setUfCliente(cidadeNome[1]);
    }
    setLoading(false);
  };

  const fetchCarroDados = async () => {
    setLoading(true);
    const data = {
      placa,
      consulta_api: false,
    };

    const response = await apiClient.post("/veiculos/placa", data);
    if (response.sucesso) {
      const dados = response.dados;
      setVeiculo(dados);
      setMarca(dados.marca);
      setModelo(dados.modelo);
      setSubmodelo(dados.submodelo);
      setVersao(dados.versao);
      setCor(dados.cor);
      setAno(dados.ano);
      setAnoModelo(dados.ano_modelo);
      setSituacao(dados.situacao);
      setOrigem(dados.origem);
      setMunicipio(dados.municipio);
      setUf(dados.uf);
      setLogo(dados.logo);

      if (dados.cliente) {
        await fetchClienteDadosId(dados.cliente.id);
        setEtapa(3);
      } else {
        setEtapa(2);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const toggle = () => {
    setEtapa(1);
    setCliente(null);
    setFilial({});
    setPlaca("");
    setMarca("");
    setModelo("");
    setSubmodelo("");
    setVersao("");
    setCor("");
    setAno("");
    setAnoModelo("");

    setNome("");
    setCnpj("");
    setCpf("");
    setEmail("");
    setTelefone("");
    setPerfil("fisica");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCidade("");
    setComplemento("");
    setCep("");

    setServicosSelecionados([]);
    setDescricao("");
    setDataRealizado("");
    setDesconto("");
    setTotalDescontado(0);
    setTotal(0);
    setStatusOrdem("novo");
    setFormaPagamento({});
    setServico({});
    setExpediente({});
    setServicosAdicionais([]);
    setDescricaoAdicional("");
    setValorAdicional("");

    setModal(!modal);
  };

  const cadastrarVeiculo = async (cliente) => {
    const dataVeiculo = {
      cliente_id: cliente.id,
      placa,
      marca,
      modelo,
      submodelo,
      versao,
      cor,
      ano,
      ano_modelo: anoModelo,
      situacao,
      origem,
      municipio,
      uf,
      logo,
    };

    const response = await apiClient.post("/veiculos", dataVeiculo);
    if (response.sucesso) {
      setVeiculo(response.dados);
      setEtapa(3);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const cadastrarCliente = async () => {
    setLoading(true);

    if (!cliente) {
      const cidadeCliente = cidade + " - " + ufCliente;

      const data = {
        nome: nomeMask(nome),
        cpf_cnpj: perfil === "fisica" ? cpf : cnpj,
        email,
        telefone,
        perfil,
        endereco,
        cep,
        numero,
        bairro,
        cidade: cidadeCliente,
        complemento,
      };

      const response = await apiClient.post("/clientes", data);
      if (response.sucesso) {
        setCliente(response.dados);
        await cadastrarVeiculo(response.dados);
      } else {
        console.error(response.mensagem);
        if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    } else await cadastrarVeiculo(cliente);

    setLoading(false);
  };

  const calcularTotalAdd = (servicosIdsPlano, servico) => {
    const qtdPlano = servicosIdsPlano.filter(
      (serv) => parseInt(serv) === parseInt(servico.value)
    ).length;
    const descontoPlano = servico.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalAnteriorDesteServico =
      servico.preco * totalAnteriorDeIncidenciasDesteServico - descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const newServicos = [...servicosSelecionados, servico];

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalDesteServico =
      servico.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;

    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);

    setServicosSelecionados(newServicos);
  };

  const calcularTotalRemover = (servicosIdsPlano, index) => {
    const newServicos = [...servicosSelecionados];
    const servicoRemovido = newServicos.splice(index, 1)[0];

    const qtdPlano = servicosIdsPlano.filter(
      (serv) => parseInt(serv) === parseInt(servicoRemovido.value)
    ).length;
    const descontoPlano = servicoRemovido.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;

    let totalAnteriorDesteServico =
      servicoRemovido.preco * totalAnteriorDeIncidenciasDesteServico -
      descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;
    let totalDesteServico =
      servicoRemovido.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;
    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);
    setServicosSelecionados(newServicos);
  };

  const criar = async () => {
    setLoading(true);

    const servicosIds = servicosSelecionados.map((servico) => servico.value);

    const data = {
      cliente_id: cliente.id,
      veiculo_id: veiculo.id,
      filial_id: filial.value,
      expediente_id: expediente.value,
      descricao,
      desconto: !desconto || desconto === "" ? 0 : desconto,
      data_realizado: dataRealizado,
      total: !total || total === "" ? 0 : total,
      forma_pagamento_id: formaPagamento.value,
      servicos_ids: servicosIds,
      status: statusOrdem,
      servicos_adicionais: servicosAdicionais,
    };

    const response = await apiClient.post("/ordens-servico", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-xl"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nova Ordem de Serviço
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="live-preview">
          <div className="position-relative m-4">
            <Progress
              value={etapa === 1 ? 0 : etapa === 2 ? 50 : 100}
              style={{ height: "1px" }}
            />
            <Button
              size="sm"
              color={etapa === 1 ? "primary" : "success"}
              className="position-absolute top-0 start-0 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              1
            </Button>
            <Button
              size="sm"
              color={
                etapa === 1 ? "light" : etapa === 2 ? "primary" : "success"
              }
              className="position-absolute top-0 start-50 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              2
            </Button>
            <Button
              size="sm"
              color={etapa === 1 ? "light" : etapa === 2 ? "light" : "primary"}
              className="position-absolute top-0 start-100 translate-middle rounded-pill"
              style={{ width: "2rem", height: "2rem" }}
            >
              3
            </Button>
          </div>
        </div>
        {etapa === 1 ? (
          <>
            <Row className="mt-3">
              <Col md={3}></Col>
              <Col md={6}>
                <Label for="placa" className="form-label">
                  Placa
                </Label>
                <Input
                  type="text"
                  id="placa"
                  name="placa"
                  placeholder="Digite a placa do veículo"
                  value={placa}
                  onChange={(e) => formatPlaca(e.target.value)}
                  maxLength={7}
                />
              </Col>
              <Col md={3}></Col>
            </Row>
            <div className="hstack gap-2 justify-content-center mt-5">
              <Link
                to="#"
                className="btn btn-link link-primary fw-medium"
                onClick={() => toggle()}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <button
                className="btn btn-primary"
                onClick={() => fetchCarroDados()}
                disabled={loading}
              >
                {loading && (
                  <Spinner size="sm" color="light" className="me-2" />
                )}
                Pesquisar Veículo
              </button>
            </div>
          </>
        ) : etapa === 2 ? (
          <>
            <Row className="mt-3">
              <Col md={6}>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="placa" className="form-label">
                      Placa
                    </Label>
                    <Input
                      type="text"
                      id="placa"
                      name="placa"
                      placeholder="Digite a placa do veículo"
                      value={placa}
                      onChange={(e) => formatPlaca(e.target.value)}
                      maxLength={7}
                    />
                  </Col>
                  <Col md="6">
                    <Label className="form-label"></Label>
                    <Button
                      className="btn btn-primary mt-4"
                      onClick={() => fetchCarroDados(placa)}
                      disabled={loading}
                    >
                      {loading && (
                        <Spinner size="sm" color="light" className="me-2" />
                      )}
                      Consultar
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="marca" className="form-label">
                      Marca
                    </Label>
                    <Input
                      type="text"
                      id="marca"
                      name="marca"
                      value={marca}
                      onChange={(e) => setMarca(e.target.value)}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="modelo" className="form-label">
                      Modelo
                    </Label>
                    <Input
                      type="text"
                      id="modelo"
                      name="modelo"
                      value={modelo}
                      onChange={(e) => setModelo(e.target.value)}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="submodelo" className="form-label">
                      Submodelo
                    </Label>
                    <Input
                      type="text"
                      id="submodelo"
                      name="submodelo"
                      value={submodelo}
                      onChange={(e) => setSubmodelo(e.target.value)}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="versao" className="form-label">
                      Versão
                    </Label>
                    <Input
                      type="text"
                      id="versao"
                      name="versao"
                      value={versao}
                      onChange={(e) => setVersao(e.target.value)}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="cor" className="form-label">
                      Cor
                    </Label>
                    <Input
                      type="text"
                      id="cor"
                      name="cor"
                      value={cor}
                      onChange={(e) => setCor(e.target.value)}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="ano" className="form-label">
                      Ano
                    </Label>
                    <Input
                      type="text"
                      id="ano"
                      name="ano"
                      value={ano}
                      onChange={(e) => setAno(e.target.value)}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="anoModelo" className="form-label">
                      Ano Modelo
                    </Label>
                    <Input
                      type="text"
                      id="anoModelo"
                      name="anoModelo"
                      value={anoModelo}
                      onChange={(e) => setAnoModelo(e.target.value)}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="situacao" className="form-label">
                      Situação
                    </Label>
                    <Input
                      type="text"
                      id="situacao"
                      name="situacao"
                      value={situacao}
                      onChange={(e) => setSituacao(e.target.value)}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="origem" className="form-label">
                      Origem
                    </Label>
                    <Input
                      type="text"
                      id="origem"
                      name="origem"
                      value={origem}
                      onChange={(e) => setOrigem(e.target.value)}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="municipio" className="form-label">
                      Município
                    </Label>
                    <Input
                      type="text"
                      id="municipio"
                      name="municipio"
                      value={municipio}
                      onChange={(e) => setMunicipio(e.target.value)}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="uf" className="form-label">
                      UF
                    </Label>
                    <Input
                      type="text"
                      id="uf"
                      name="uf"
                      value={uf}
                      onChange={(e) => setUf(e.target.value)}
                      readOnly
                    />
                  </Col>
                  <Col md="6" className="justify-content-center">
                    <img
                      src={logo}
                      alt="Logo"
                      className="img-fluid"
                      style={{ maxHeight: "100px" }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mt-5">
                  <Col md="6">
                    <div>
                      <Label htmlFor="perfil" className="form-label">
                        Perfil <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="perfil"
                        className="form-control"
                        type="select"
                        value={perfil}
                        onChange={(e) => setPerfil(e.target.value)}
                      >
                        <option value="fisica">Pessoa Física</option>
                        <option value="juridica">Pessoa Jurídica</option>
                      </Input>
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <Label htmlFor="telefone" className="form-label">
                        Telefone <span className="text-danger">*</span>
                      </Label>
                      <InputMask
                        mask="(99) 99999-9999"
                        placeholder="(11) 99999-9999"
                        value={telefone || ""}
                        onChange={(e) => setTelefone(e.target.value)}
                        onBlur={() => fetchClienteDados()}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="nome" className="form-label">
                        Nome <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="nome"
                        className="form-control"
                        type="text"
                        placeholder="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        maxLength={150}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <Label htmlFor="email" className="form-label">
                        E-mail
                      </Label>
                      <Input
                        id="email"
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => formatEmail(e.target.value)}
                        maxLength={150}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  {perfil === "fisica" ? (
                    <Col md="6">
                      <div>
                        <Label htmlFor="cpf" className="form-label">
                          CPF
                        </Label>
                        <InputMask
                          mask="999.999.999-99"
                          placeholder="999.999.999-99"
                          value={cpf || ""}
                          onChange={(e) => setCpf(e.target.value)}
                          disabled={!cliente ? false : true}
                        >
                          {(inputProps) => (
                            <Input
                              type="text"
                              className="form-control"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </div>
                    </Col>
                  ) : (
                    <Col md="6">
                      <div>
                        <Label htmlFor="cnpj" className="form-label">
                          CNPJ
                        </Label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          placeholder="99.999.999/9999-99"
                          value={cnpj || ""}
                          onChange={(e) => setCnpj(e.target.value)}
                          disabled={!cliente ? false : true}
                        >
                          {(inputProps) => (
                            <Input
                              type="text"
                              className="form-control"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </div>
                    </Col>
                  )}
                  <Col md="6">
                    <div>
                      <Label htmlFor="cep" className="form-label">
                        CEP
                      </Label>
                      <InputMask
                        mask="99999-999"
                        placeholder="00000-000"
                        value={cep || ""}
                        onBlur={(e) => handleCep(e.target.value)}
                        onChange={(e) => setCep(e.target.value)}
                        disabled={!cliente ? false : true}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="endereco" className="form-label">
                        Endereço
                      </Label>
                      <Input
                        id="endereco"
                        className="form-control"
                        type="text"
                        placeholder="Endereço"
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                        maxLength={255}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <Label htmlFor="numero" className="form-label">
                        Número
                      </Label>
                      <Input
                        id="numero"
                        className="form-control"
                        type="text"
                        placeholder="Número"
                        value={numero}
                        onChange={(e) =>
                          setNumero(e.target.value.replace(/\D/g, ""))
                        }
                        maxLength={8}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="bairro" className="form-label">
                        Bairro
                      </Label>
                      <Input
                        id="bairro"
                        className="form-control"
                        type="text"
                        placeholder="Bairro"
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                        maxLength={150}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <Label htmlFor="cidade" className="form-label">
                        Cidade
                      </Label>
                      <Input
                        id="cidade"
                        className="form-control"
                        type="text"
                        placeholder="Cidade"
                        value={cidade}
                        onChange={(e) => setCidade(e.target.value)}
                        maxLength={150}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                  <Col md="12" className="mt-3">
                    <div>
                      <Label htmlFor="complemento" className="form-label">
                        Complemento
                      </Label>
                      <Input
                        id="complemento"
                        className="form-control"
                        type="text"
                        placeholder="Complemento"
                        value={complemento}
                        onChange={(e) => setComplemento(e.target.value)}
                        maxLength={255}
                        disabled={!cliente ? false : true}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="hstack gap-2 justify-content-center mt-5">
                  <Link
                    to="#"
                    className="btn btn-link link-primary fw-medium"
                    onClick={() => toggle()}
                    disabled={loading}
                  >
                    <i className="ri-close-line me-1 align-middle"></i> Fechar
                  </Link>
                  {!cliente ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => cadastrarCliente()}
                      disabled={loading}
                    >
                      Cadastrar Cliente e Veículo
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={() => cadastrarVeiculo(cliente)}
                    >
                      Adicionar Veículo ao Cliente
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mt-3">
              <Col md={6}>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="placa" className="form-label">
                      Placa
                    </Label>
                    <Input
                      type="text"
                      id="placa"
                      name="placa"
                      value={placa}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="marca" className="form-label">
                      Marca
                    </Label>
                    <Input
                      type="text"
                      id="marca"
                      name="marca"
                      value={marca}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="modelo" className="form-label">
                      Modelo
                    </Label>
                    <Input
                      type="text"
                      id="modelo"
                      name="modelo"
                      value={modelo}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label for="cor" className="form-label">
                      Cor
                    </Label>
                    <Input
                      type="text"
                      id="cor"
                      name="cor"
                      value={cor}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="cliente" className="form-label">
                      Cliente
                    </Label>
                    <Input
                      type="text"
                      id="cliente"
                      name="cliente"
                      value={nome}
                      readOnly
                    />
                  </Col>
                  <Col md="6">
                    <Label htmlFor="statusOrdem" className="form-label">
                      Status da Ordem de Serviço
                    </Label>
                    <Input
                      id="statusOrdem"
                      className="form-control"
                      type="select"
                      value={statusOrdem}
                      onChange={(e) => setStatusOrdem(e.target.value)}
                    >
                      <option value="novo">Novo</option>
                      <option value="pago">Pago</option>
                      <option value="em-andamento">Em Andamento</option>
                      <option value="inadimplente">Inadimplente</option>
                    </Input>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="9">
                    <Label htmlFor="servicos" className="form-label">
                      Serviços
                    </Label>
                    <Select
                      value={servico}
                      onChange={(sortBy) => {
                        setServico(sortBy);
                      }}
                      options={optionsServicos}
                      id="servicos"
                      placeholder={
                        loading ? "Carregando..." : "Selecione o serviço"
                      }
                      disabled={loading}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #ced4da",
                          color: "#aaa",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#aaa",
                        }),
                      }}
                    />
                  </Col>
                  <Col md="3" className="pt-2">
                    <button
                      className="btn btn-sm btn-success mt-4"
                      onClick={() => {
                        if (servico?.value) {
                          calcularTotalAdd(cliente?.servicos, servico);
                          setServico({});
                        }
                      }}
                    >
                      Adicionar
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="filial" className="form-label">
                      Filial
                    </Label>
                    <Select
                      value={filial}
                      onChange={(sortBy) => {
                        setFilial(sortBy);
                      }}
                      options={optionsFiliais}
                      id="filial"
                      placeholder={
                        loading ? "Carregando..." : "Selecione a filial"
                      }
                      disabled={loading}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #ced4da",
                          color: "#aaa",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#aaa",
                        }),
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="expediente" className="form-label">
                      Expediente
                    </Label>
                    <Select
                      value={expediente}
                      onChange={(sortBy) => {
                        setExpediente(sortBy);
                      }}
                      options={optionsExpedientes}
                      id="expediente"
                      placeholder={
                        loading ? "Carregando..." : "Selecione o expediente"
                      }
                      disabled={loading}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #ced4da",
                          color: "#aaa",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#aaa",
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <Label for="descricao" className="form-label">
                      Observação
                    </Label>
                    <Input
                      type="textarea"
                      id="descricao"
                      name="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="dataRealizado" className="form-label">
                      Data Realizado
                    </Label>
                    <Input
                      type="date"
                      id="dataRealizado"
                      name="dataRealizado"
                      value={dataRealizado}
                      onChange={(e) => setDataRealizado(e.target.value)}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="total" className="form-label">
                      Total
                    </Label>
                    <NumericFormat
                      id="total"
                      placeholder="R$ 0,00"
                      className="form-control"
                      value={totalDescontado || "0"}
                      onValueChange={() => {}}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$ "
                      fixedDecimalScale={true}
                      allowNegative={false}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label for="desconto" className="form-label">
                      Desconto
                    </Label>
                    <NumericFormat
                      id="desconto"
                      placeholder="0,00 %"
                      className="form-control"
                      value={desconto || ""}
                      onValueChange={({ value }) => {
                        setDesconto(value);
                        setTotalDescontado(
                          total *
                            (1 - parseFloat(value === "" ? "0" : value) / 100)
                        );
                      }}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      suffix=" %"
                      fixedDecimalScale={true}
                      allowNegative={false}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="formaPagamento" className="form-label">
                      Forma de Pagamento
                    </Label>
                    <Select
                      value={formaPagamento}
                      onChange={(sortBy) => {
                        setFormaPagamento(sortBy);
                      }}
                      options={optionsFormasPagamento}
                      id="formaPagamento"
                      placeholder={
                        loading
                          ? "Carregando..."
                          : "Selecione a forma de pagamento"
                      }
                      disabled={loading}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #ced4da",
                          color: "#aaa",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#aaa",
                        }),
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-3 border-top pt-3">
              <h4>Serviços Adicionais</h4>
              <Col md="5">
                <Label for="descricao" className="form-label">
                  Descrição
                </Label>
                <Input
                  type="text"
                  id="descricao"
                  name="descricao"
                  value={descricaoAdicional}
                  onChange={(e) => setDescricaoAdicional(e.target.value)}
                />
              </Col>
              <Col md="5">
                <Label for="valorAdicional" className="form-label">
                  Valor
                </Label>
                <NumericFormat
                  id="valorAdicional"
                  placeholder="R$ 0,00"
                  className="form-control"
                  value={valorAdicional || ""}
                  onValueChange={({ value }) => setValorAdicional(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </Col>
              <Col md="2" className="pt-2">
                <button
                  className="btn btn-sm btn-success mt-4"
                  onClick={() => {
                    addServicoAdicional();
                  }}
                >
                  Adicionar
                </button>
              </Col>
            </Row>
            <Row className="mt-3 border-top pt-3">
              <Col md="12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Serviço</th>
                      <th>Preço</th>
                      <th>Remover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicosSelecionados.map((servico, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {servico.nome}

                          {quantidadeEmPlano(servico, cliente?.servicos) >
                            0 && (
                            <p className="m-0">
                              <p className="badge bg-light text-primary me-1">
                                Presente no plano do cliente
                              </p>
                            </p>
                          )}
                        </td>
                        <td>{formatCurrency(servico.preco)}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              calcularTotalRemover(cliente?.servicos, index);
                            }}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                    {servicosAdicionais.map((servico, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {servico.descricao}
                        </td>
                        <td>{formatCurrency(servico.preco)}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              removeServicoAdicional(servico.index);
                            }}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>

            <div className="hstack gap-2 justify-content-center mt-5">
              <Link
                to="#"
                className="btn btn-link link-primary fw-medium"
                onClick={() => toggle()}
                disabled={loading}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <button
                className="btn btn-success"
                onClick={() => criar()}
                disabled={loading}
              >
                {loading && (
                  <Spinner size="sm" color="light" className="me-2" />
                )}
                Criar
              </button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
