import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({
  modal,
  setModal,
  setRefresh,
  pagamento,
  formatCurrency,
}) => {
  const [loading, setLoading] = useState(false);
  const [nomeCliente, setNomeCliente] = useState("");
  const [descricao, setDescricao] = useState("");
  const [nomeFormaPagamento, setNomeFormaPagamento] = useState("");
  const [meses, setMeses] = useState(1);
  const [valor, setValor] = useState("0");
  const [statusPagamento, setStatus] = useState("novo");

  const toggle = () => {
    setNomeCliente("");
    setDescricao("");
    setNomeFormaPagamento("");
    setMeses(1);
    setValor("0");
    setStatus("novo");

    setModal(!modal);
  };

  const editar = async () => {
    setLoading(true);

    const data = {
      status: statusPagamento,
    };

    const response = await apiClient.put(
      `/pacotes-pagamento/mudar-status/${pagamento?.id}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      setNomeCliente(pagamento?.cliente?.nome);
      setDescricao(pagamento?.descricao);
      setNomeFormaPagamento(pagamento?.forma_pagamento?.nome);
      setValor(formatCurrency(pagamento?.valor_total));
      setStatus(pagamento?.statusPagamento);
    };
    if (modal && pagamento) {
      fetch();
    }
  }, [modal, pagamento]);
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Pagamento
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome_cliente" className="form-label">
                  Nome do Cliente
                </Label>
                <Input
                  id="nome_cliente"
                  className="form-control"
                  type="text"
                  placeholder="Nome do Cliente"
                  value={nomeCliente}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="nome_forma_pagamento" className="form-label">
                  Forma de Pagamento
                </Label>
                <Input
                  id="nome_forma_pagamento"
                  className="form-control"
                  type="text"
                  placeholder="Nome da Forma de Pagamento"
                  value={nomeFormaPagamento}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="nome_pacote" className="form-label">
                  Descrição
                </Label>
                <Input
                  id="nome_pacote"
                  className="form-control"
                  type="textarea"
                  placeholder="Nome do Plano"
                  value={descricao}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="valor" className="form-label">
                  Valor
                </Label>
                <Input
                  id="valor"
                  className="form-control"
                  type="text"
                  placeholder="Valor"
                  value={valor}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="statusPagamento" className="form-label">
                  Status
                </Label>
                <Input
                  type="select"
                  id="statusPagamento"
                  className="form-select"
                  value={statusPagamento}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="novo">Novo</option>
                  <option value="pago">Pago</option>
                  <option value="cancelado">Cancelado</option>
                </Input>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar()}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
