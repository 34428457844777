import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalMudarStatus = ({ modal, setModal, setRefresh, ordemServico, filial_id }) => {
  const [loading, setLoading] = useState(false);
  const [statusOrdem, setStatusOrdem] = useState("novo");

  const toggle = () => {
    setStatusOrdem("novo");
    setModal(!modal);
  };

  const mudar = async (ordemServico) => {
    setLoading(true);

    const servicosIds = ordemServico.servicos.map((servico) => servico.id);

    const data = {
      descricao: ordemServico.descricao,
      desconto: ordemServico.desconto,
      status: statusOrdem,
      total: ordemServico.total,
      forma_pagamento_id: ordemServico.forma_pagamento_id,
      servicos_ids: servicosIds,
      filial_id
    };

    const response = await apiClient.put(
      `/ordens-servico/${ordemServico.id}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      setStatusOrdem(ordemServico?.status);
    };
    if (ordemServico) fetchStatus();
  }, [ordemServico]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Mudar Status da OS #{ordemServico?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="statusOrdem" className="form-label">
                  Status
                </Label>
                <Input
                  id="statusOrdem"
                  className="form-control"
                  type="select"
                  value={statusOrdem}
                  onChange={(e) => setStatusOrdem(e.target.value)}
                >
                  <option value="novo">Novo</option>
                  <option value="pago">Pago</option>
                  <option value="em-andamento">Em Andamento</option>
                  <option value="inadimplente">Inadimplente</option>
                  <option value="finalizada">Finalizado</option>
                </Input>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => mudar(ordemServico)}
              disabled={loading}
            >
              Mudar Status
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMudarStatus;
