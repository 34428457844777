import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalNovoCarro = ({ modal, setModal, setRefresh, cliente }) => {
  const [loading, setLoading] = useState(false);
  const [loadingConsulta, setLoadingConsulta] = useState(false);

  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [submodelo, setSubmodelo] = useState("");
  const [versao, setVersao] = useState("");
  const [cor, setCor] = useState("");
  const [ano, setAno] = useState("");
  const [anoModelo, setAnoModelo] = useState("");
  const [situacao, setSituacao] = useState("");
  const [origem, setOrigem] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [uf, setUf] = useState("");
  const [logo, setLogo] = useState("");

  // aceitar apenas letras e números, fazer as letras ficarem maiúsculas
  const formatPlaca = (placa) => {
    setPlaca(placa.replace(/[^a-zA-Z0-9]/g, "").toUpperCase());
  };

  const fetchCarroDados = useCallback(async (placa) => {
    setLoadingConsulta(true);
    setLoading(true);
    const data = {
      placa,
      consulta_api: false,
    };

    const response = await apiClient.post("/veiculos/placa", data);
    if (response.sucesso) {
      const dados = response.dados;
      setMarca(dados.marca);
      setModelo(dados.modelo);
      setSubmodelo(dados.submodelo);
      setVersao(dados.versao);
      setCor(dados.cor);
      setAno(dados.ano);
      setAnoModelo(dados.ano_modelo);
      setSituacao(dados.situacao);
      setOrigem(dados.origem);
      setMunicipio(dados.municipio);
      setUf(dados.uf);
      setLogo(dados.logo);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);

      setLoadingConsulta(false);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
      setLoadingConsulta(false);
    }

    setLoading(false);
  }, []);

  const toggle = () => {
    setPlaca("");
    setMarca("");
    setModelo("");
    setSubmodelo("");
    setVersao("");
    setCor("");
    setAno("");
    setAnoModelo("");
    setSituacao("");
    setOrigem("");
    setMunicipio("");

    setModal(!modal);
  };

  const criar = async (cliente) => {
    setLoading(true);

    const data = {
      cliente_id: cliente.id,
      placa,
      marca,
      modelo,
      submodelo,
      versao,
      cor,
      ano,
      ano_modelo: anoModelo,
      situacao,
      origem,
      municipio,
      uf,
      logo,
    };

    const response = await apiClient.post("/veiculos", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Novo Veículo
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <p className="mb-4 text-muted">
          <b>Cliente</b>: {cliente?.nome}
        </p>
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <Label for="placa" className="form-label">
                Placa
              </Label>
              <Input
                type="text"
                id="placa"
                name="placa"
                placeholder="Digite a placa do veículo"
                value={placa}
                onChange={(e) => formatPlaca(e.target.value)}
                maxLength={7}
              />
            </Col>
            <Col md="6">
              <Label className="form-label"></Label>
              <Button
                className="btn btn-primary mt-4"
                onClick={() => fetchCarroDados(placa)}
                disabled={loadingConsulta}
              >
                {loading && loadingConsulta && (
                  <Spinner size="sm" color="light" className="me-2" />
                )}
                Consultar
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="marca" className="form-label">
                Marca
              </Label>
              <Input
                type="text"
                id="marca"
                name="marca"
                value={marca}
                onChange={(e) => setMarca(e.target.value)}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="modelo" className="form-label">
                Modelo
              </Label>
              <Input
                type="text"
                id="modelo"
                name="modelo"
                value={modelo}
                onChange={(e) => setModelo(e.target.value)}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="submodelo" className="form-label">
                Submodelo
              </Label>
              <Input
                type="text"
                id="submodelo"
                name="submodelo"
                value={submodelo}
                onChange={(e) => setSubmodelo(e.target.value)}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="versao" className="form-label">
                Versão
              </Label>
              <Input
                type="text"
                id="versao"
                name="versao"
                value={versao}
                onChange={(e) => setVersao(e.target.value)}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="cor" className="form-label">
                Cor
              </Label>
              <Input
                type="text"
                id="cor"
                name="cor"
                value={cor}
                onChange={(e) => setCor(e.target.value)}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="ano" className="form-label">
                Ano
              </Label>
              <Input
                type="text"
                id="ano"
                name="ano"
                value={ano}
                onChange={(e) => setAno(e.target.value)}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="anoModelo" className="form-label">
                Ano Modelo
              </Label>
              <Input
                type="text"
                id="anoModelo"
                name="anoModelo"
                value={anoModelo}
                onChange={(e) => setAnoModelo(e.target.value)}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="situacao" className="form-label">
                Situação
              </Label>
              <Input
                type="text"
                id="situacao"
                name="situacao"
                value={situacao}
                onChange={(e) => setSituacao(e.target.value)}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="origem" className="form-label">
                Origem
              </Label>
              <Input
                type="text"
                id="origem"
                name="origem"
                value={origem}
                onChange={(e) => setOrigem(e.target.value)}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="municipio" className="form-label">
                Município
              </Label>
              <Input
                type="text"
                id="municipio"
                name="municipio"
                value={municipio}
                onChange={(e) => setMunicipio(e.target.value)}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="uf" className="form-label">
                UF
              </Label>
              <Input
                type="text"
                id="uf"
                name="uf"
                value={uf}
                onChange={(e) => setUf(e.target.value)}
                readOnly
              />
            </Col>
            <Col md="6" className="justify-content-center">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                style={{ maxHeight: "100px" }}
              />
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => criar(cliente)}
              disabled={loading}
            >
              Cadastrar Veículo
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovoCarro;
