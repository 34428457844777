import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { checkLastLogin, nomeMask } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, fornecedor }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [complemento, setComplemento] = useState("");
  const [nomeVendedor, setNomeVendedor] = useState("");
  const [telefoneVendedor, setTelefoneVendedor] = useState("");

  const toggle = () => {
    setNome("");
    setCnpj("");
    setEmail("");
    setTelefone("");
    setCep("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCidade("");
    setComplemento("");
    setNomeVendedor("");
    setTelefoneVendedor("");

    setModal(!modal);
  };

  const handleCep = async (cepPesquisa) => {
    const _cep = cepPesquisa.replace(/[^0-9]/g, "");
    setCep(_cep);

    if (_cep.length !== 8) return;
    try {
      const response = await apiClient.get(`cep/${_cep}`);
      const dadosRetorno = response.dados;

      setEndereco(dadosRetorno.logradouro);
      setBairro(dadosRetorno.bairro);
      setCidade(`${dadosRetorno.localidade} - ${dadosRetorno.uf}`);
    } catch (erro) {
      console.error(erro);
      toast.error("CEP não encontrado");
    }
  };

  const formatEmail = (value) => {
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const editar = async () => {
    setLoading(true);

    const data = {
      nome: nomeMask(nome),
      cnpj,
      email,
      telefone,
      cep,
      endereco,
      numero,
      bairro,
      cidade,
      complemento,
      nome_vendedor: nomeVendedor,
      telefone_vendedor: telefoneVendedor,
    };

    const response = await apiClient.put(`/fornecedores/${fornecedor.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      setNome(fornecedor.nome);
      setCnpj(fornecedor.cnpj);
      setEmail(fornecedor.email);
      setTelefone(fornecedor.telefone);
      setCep(fornecedor.cep);
      setEndereco(fornecedor.endereco);
      setNumero(fornecedor.numero);
      setBairro(fornecedor.bairro);
      setCidade(fornecedor.cidade);
      setComplemento(fornecedor.complemento);
      setNomeVendedor(fornecedor.nome_vendedor);
      setTelefoneVendedor(fornecedor.telefone_vendedor);
    };
    if (fornecedor) {
      fetch();
    }
  }, [fornecedor]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Fornecedor {fornecedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome || ""}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="cnpj" className="form-label">
                  CNPJ <span className="text-danger">*</span>
                </Label>
                <InputMask
                  mask="99.999.999/9999-99"
                  placeholder="99.999.999/9999-99"
                  value={cnpj || ""}
                  onChange={(e) => setCnpj(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail 
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email || ""}
                  onChange={(e) => formatEmail(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone <span className="text-danger">*</span>
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  value={telefone || ""}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="cep" className="form-label">
                  CEP
                </Label>
                <InputMask
                  mask="99999-999"
                  placeholder="00000-000"
                  value={cep || ""}
                  onBlur={(e) => handleCep(e.target.value)}
                  onChange={(e) => setCep(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="endereco" className="form-label">
                  Endereço
                </Label>
                <Input
                  id="endereco"
                  className="form-control"
                  type="text"
                  placeholder="Endereço"
                  value={endereco || ""}
                  onChange={(e) => setEndereco(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="numero" className="form-label">
                  Número
                </Label>
                <Input
                  id="numero"
                  className="form-control"
                  type="text"
                  placeholder="Número"
                  value={numero || ""}
                  onChange={(e) => setNumero(e.target.value.replace(/\D/g, ""))}
                  maxLength={8}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="bairro" className="form-label">
                  Bairro
                </Label>
                <Input
                  id="bairro"
                  className="form-control"
                  type="text"
                  placeholder="Bairro"
                  value={bairro || ""}
                  onChange={(e) => setBairro(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="cidade" className="form-label">
                  Cidade
                </Label>
                <Input
                  id="cidade"
                  className="form-control"
                  type="text"
                  placeholder="Cidade"
                  value={cidade || ""}
                  onChange={(e) => setCidade(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="complemento" className="form-label">
                  Complemento
                </Label>
                <Input
                  id="complemento"
                  className="form-control"
                  type="text"
                  placeholder="Complemento"
                  value={complemento || ""}
                  onChange={(e) => setComplemento(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <h4>Vendedor</h4>
            <Col md="6">
              <div>
                <Label htmlFor="nome_vendedor" className="form-label">
                  Nome
                </Label>
                <Input
                  id="nome_vendedor"
                  className="form-control"
                  type="text"
                  placeholder="Nome do Vendedor"
                  value={nomeVendedor || ""}
                  onChange={(e) => setNomeVendedor(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone_vendedor" className="form-label">
                  Telefone do Vendedor
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  value={telefoneVendedor || ""}
                  onChange={(e) => setTelefoneVendedor(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar()}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
