import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalApagar = ({ modal, setModal, setRefresh, pacote }) => {
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const apagar = async () => {
    setLoading(true);

    const response = await apiClient.delete(`/pacotes/${pacote.id}`, {});
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Apagar {pacote?.pacote}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>O serviço será apagado
                permanentemente. Caso tenha certeza de que deseja apagar este
                serviço, clique no botão <b className="text-danger">Apagar</b>.
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-danger"
              onClick={() => apagar()}
              disabled={loading}
            >
              Apagar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
