import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';


export const getTodos = createAsyncThunk("todos/getTodos", async () => {
  return null;
});

export const addNewTodo = createAsyncThunk("todos/addNewTodo", async (project) => {
  return project;
});

export const updateTodo = createAsyncThunk("todos/updateTodo", async (project) => {
  return project;
});

export const deleteTodo = createAsyncThunk("todos/deleteTodo", async (project) => {
  return project;
});

export const getProjects = createAsyncThunk("todos/getProjects", async () => {
  return null;
});

export const addNewProject = createAsyncThunk("todos/addNewProject", async (project) => {
  return project;
});