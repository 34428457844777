import React, { useState, useCallback, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalAdicionarPacotes = ({ modal, setModal, setRefresh, cliente }) => {
  const [loading, setLoading] = useState(true);

  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [filial, setFilial] = useState({});

  const [pacote, setPacote] = useState({});
  const [optionsPacotes, setOptionsPacotes] = useState([]);
  const [pacotesSelecionados, setPacotesSelecionados] = useState([]);
  const [optionsFormasPagamento, setOptionsFormasPagamento] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState({});
  const [valorTotal, setValorTotal] = useState("");
  const [statusVenda, setStatusVenda] = useState("novo");

  const toggle = () => {
    setPacote({});
    setPacotesSelecionados([]);
    setModal(!modal);
  };

  const fetchFormasPagamento = useCallback(async () => {
    const response = await apiClient.get("/formas-pagamento");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFormasPagamento(
        dados.map((item) => ({
          label: `${item?.nome}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (userData.filiais.length > 0 && !filial?.value && userData.role !== "super-admin") {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
      setRefresh(true);
    }
  }, []);

  const fetchPacotes = useCallback(async () => {
    const response = await apiClient.get("/pacotes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsPacotes(
        dados.map((pacote) => ({
          label: `${pacote?.nome} (${formatCurrency(pacote?.preco)})`,
          nome: `${pacote?.nome}`,
          preco: `${pacote?.preco}`,
          value: `${pacote?.id}`,
          servicos: pacote?.servicos,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  const adicionar = async (cliente) => {
    setLoading(true);

    const pacotes_ids = pacotesSelecionados.map((pacote) => pacote.value) || [];

    const data = {
      pacotes_ids,
      filial_id: filial.value,
      forma_pagamento_id: formaPagamento.value,
      valor_total: valorTotal,
      status: statusVenda,
    };
    const response = await apiClient.put(
      `/clientes/${cliente.id}/pacotes/adicionar`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const calcularTotal = (pacotes) => {
    const total = pacotes.reduce((acc, pacote) => {
      return acc + parseFloat(pacote.preco);
    }, 0);
    setValorTotal(total);
  };

  useEffect(() => {
    const fetchData = async (cliente) => {
      await fetchFormasPagamento();
      await fetchFiliais();
      await fetchPacotes(cliente);
    };
    if (modal && cliente) fetchData(cliente);
  }, [cliente, modal, fetchPacotes]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Adicionar Pacotes
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-2">
          <Row className="mt-2">
            <Col md="6">
              <Label for="filial" className="form-label">
                Filial
              </Label>
              <Select
                value={filial}
                onChange={(sortBy) => {
                  setFilial(sortBy);
                }}
                options={optionsFiliais}
                id="filial"
                placeholder={loading ? "Carregando..." : "Selecione a filial"}
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="6">
              <Label for="statusVenda" className="form-label">
                Status
              </Label>
              <Input
              type="select"
              name="statusVenda"
              id="statusVenda"
              value={statusVenda}
              onChange={(e) => setStatusVenda(e.target.value)}
              disabled={loading}
              >
                <option value="novo">Novo</option>
                <option value="pago">Pago</option>
              </Input>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="formaPagamento" className="form-label">
                Forma de Pagamento
              </Label>
              <Select
                value={formaPagamento}
                onChange={(sortBy) => {
                  setFormaPagamento(sortBy);
                }}
                options={optionsFormasPagamento}
                id="formaPagamento"
                placeholder={
                  loading ? "Carregando..." : "Selecione a forma de pagamento"
                }
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="6">
              <Label for="valorTotal" className="form-label">
                Valor Total
              </Label>
              <NumericFormat
                id="valorTotal"
                placeholder="R$ 0,00"
                className="form-control"
                value={valorTotal || ""}
                onValueChange={() => {}}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                fixedDecimalScale={true}
                allowNegative={false}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="9">
              <Label htmlFor="pacotes" className="form-label">
                Pacotes
              </Label>
              <Select
                value={pacote}
                onChange={(sortBy) => {
                  setPacote(sortBy);
                }}
                options={optionsPacotes}
                id="pacotes"
                placeholder={loading ? "Carregando..." : "Selecione o pacote"}
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="3" className="pt-2">
              <button
                className="btn btn-success mt-4"
                onClick={() => {
                  if (pacote?.value) {
                    calcularTotal([...pacotesSelecionados, pacote]);
                    setPacotesSelecionados([...pacotesSelecionados, pacote]);
                    setPacote({});
                  }
                }}
                disabled={loading || !pacote?.value}
              >
                Adicionar
              </button>
            </Col>
          </Row>
          <Row className="mt-3 border-top pt-3">
            <Col md="12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Pacote</th>
                    <th>Serviços</th>
                    <th>Preço</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {pacotesSelecionados.map((pacote, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {pacote.nome}
                      </td>
                      <td>
                        {pacote.servicos?.map((servico, index) => {
                          console.log(servico);
                          return (
                            <p
                              key={index}
                              className="badge bg-light text-dark me-1"
                            >
                              {servico.servico} (x{servico.pivot.quantidade})
                            </p>
                          );
                        })}
                      </td>
                      <td>{formatCurrency(pacote.preco)}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            const newPacotes = pacotesSelecionados.filter(
                              (pacote, i) => i !== index
                            );
                            calcularTotal(newPacotes);
                            setPacotesSelecionados(newPacotes);
                          }}
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => adicionar(cliente)}
              disabled={loading}
            >
              Adicionar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarPacotes;
