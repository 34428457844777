import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import VisaoGeral from "./VisaoGeral";
import Faturamento from "./Faturamento";
import TotalClientes from './TotalClientes';
import { getLoggedinUser } from "../../helpers/api_helper";


const Dashboard = () => {
  document.title = `Dashboard | ${process.env.REACT_APP_TITLE}`;
  useEffect(() => {
    const getUserData = async () => {
      const data = await getLoggedinUser();
      if(data?.role !== "super-admin" && data?.role !== "admin"){
        window.location.href = "/ordens-servico";
      }
    };
    getUserData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <Col xl={12}>
                    <VisaoGeral />
                  </Col>
                </Row>
                <Row>
                  <Faturamento />
                  <TotalClientes />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
