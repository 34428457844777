import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFolders = createAsyncThunk("fileManager/getFolders", async () => {
  return null;
});

export const addNewFolder = createAsyncThunk("fileManager/addNewFolder", async (folder) => {
  return folder;
});

export const updateFolder = createAsyncThunk("fileManager/updateFolder", async (folder) => {
  return folder;
});

export const deleteFolder = createAsyncThunk("fileManager/deleteFolder", async (folder) => {
  return folder;
});

export const getFiles = createAsyncThunk("fileManager/getFiles", async () => {
  return null;
});

export const addNewFile = createAsyncThunk("fileManager/addNewFile", async (file) => {
  return file;
});

export const updateFile = createAsyncThunk("fileManager/updateFile", async (file) => {
  return file;
});

export const deleteFile = createAsyncThunk("fileManager/deleteFile", async (file) => {
  return file;
});