import React, { useState, useCallback, useEffect } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Widgets = () => {
  const [loading, setLoading] = useState(true);
  const [widgets, setWidgets] = useState([
    {
      id: 1,
      cardColor: "primary",
      label: "Total em Vendas",
      counter: "0",
      link: "Ver Tudo",
      bgcolor: "success",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "R$ ",
      separator: ",",
      suffix: "",
    },
    {
      id: 2,
      cardColor: "secondary",
      label: "Serviços",
      counter: "0",
      link: "Ver Tudo",
      bgcolor: "info",
      icon: "bx bx-briefcase",
      decimals: 0,
      prefix: "",
      separator: ".",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "success",
      label: "Clientes",
      counter: "0",
      link: "Ver Tudo",
      bgcolor: "warning",
      icon: "bx bx-user-circle",
      decimals: 0,
      prefix: "",
      separator: ".",
      suffix: " clientes",
    },
    {
      id: 4,
      cardColor: "info",
      label: "Total de Carros",
      counter: "0",
      link: "Ver Tudo",
      bgcolor: "danger",
      icon: "bx bx-car",
      decimals: 0,
      prefix: "",
      separator: ".",
      suffix: " veículos",
    },
  ]);

  const fetchDashboardData = useCallback(async () => {
    const userData = await getLoggedinUser();
    const data = {
      filial_id: userData?.filiais.length > 0 ? userData.filiais[0].id : null,
    };
    const response = await apiClient.post("/dashboard/data", data);
    if (response.sucesso) {
      const dados = response.dados;
      setWidgets([
        {
          ...widgets[0],
          counter: dados.total_vendas,
        },
        {
          ...widgets[1],
          counter: dados.total_os,
        },
        {
          ...widgets[2],
          counter: dados.total_clientes,
        },
        {
          ...widgets[3],
          counter: dados.total_veiculos,
        },
      ]);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchDashboardData();
      setLoading(false);
    };

    fetchData();
  }, [loading]);

  return (
    <React.Fragment>
      {widgets.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target={item.counter}>
                      <CountUp
                        start={0}
                        prefix={item.prefix}
                        suffix={item.suffix}
                        separator={item.separator}
                        end={item.counter}
                        decimals={item.decimals}
                        duration={4}
                      />
                    </span>
                  </h4>
                  {/* <Link to="#" className="text-decoration-underline">
                    {item.link}
                  </Link> */}
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={"avatar-title rounded fs-3 bg-" + item.bgcolor}
                  >
                    <i className={`${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
