import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProjectList = createAsyncThunk("projects/getProjectList", async () => {
   return null;
});

export const addProjectList = createAsyncThunk("projects/addProjectList", async (project) => {
    return project;
});

export const updateProjectList = createAsyncThunk("projects/updateProjectList", async (project) => {
    return project;
});

export const deleteProjectList = createAsyncThunk("projects/deleteProjectList", async (data) => {
   return data;
});