import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Select from "react-select";
import {
  checkLastLogin,
  nomeMask,
  formatEmail,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({
  modal,
  setModal,
  setRefresh,
  optionsFiliais,
  usuario,
}) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [login, setLogin] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState("usuario");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [filial, setFilial] = useState({});
  const [funcao, setFuncao] = useState({});
  const [funcoesSelecionadas, setFuncoesSelecionadas] = useState([]);
  const [optionsFuncoes, setOptionsFuncoes] = useState([]);

  const toggle = () => {
    setNome("");
    setLogin("");
    setCpf("");
    setEmail("");
    setTelefone("");
    setTipo("usuario");
    setPassword("");
    setConfirmPassword("");
    setFuncao({});
    setFilial({});
    setFuncoesSelecionadas([]);
    setOptionsFuncoes([]);

    setModal(!modal);
  };

  const editar = async (usuario) => {
    setLoading(true);

    const funcoesIds = funcoesSelecionadas.map((funcao) => funcao.value);

    const data = {
      nome: nomeMask(nome),
      login,
      cpf,
      email,
      telefone,
      tipo,
      password,
      password_confirmation: confirmPassword,
      funcoes: funcoesIds,
      filial_id: filial?.value,
    };

    const response = await apiClient.put(`/usuarios/${usuario.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchFuncoes = useCallback(async () => {
    const response = await apiClient.get("/funcoes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFuncoes(
        dados.map((funcao) => ({
          label: `${funcao?.funcao}`,
          value: `${funcao?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetch = async (usuario) => {
      setNome(usuario?.nome);
      setLogin(usuario?.login);
      setCpf(usuario?.cpf);
      setEmail(usuario?.email);
      setTelefone(usuario?.telefone);
      setTipo(usuario?.tipo);
      if (usuario?.filiais?.length > 0) {
        setFilial({
          label: usuario?.filiais[0].nome,
          value: usuario?.filiais[0].id,
        });
      }
      setFuncoesSelecionadas(
        usuario?.funcoes?.map((funcao) => ({
          label: funcao?.funcao,
          value: funcao?.id,
        }))
      );
      await fetchFuncoes();
    };
    if (modal) fetch(usuario);
  }, [modal, fetchFuncoes]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar {usuario?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome || ""}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="cpf" className="form-label">
                  CPF
                </Label>
                <InputMask
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  value={cpf || ""}
                  onChange={(e) => setCpf(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      id="cpf"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="4">
              <div>
                <Label htmlFor="login" className="form-label">
                  Login <span className="text-danger">*</span>
                </Label>
                <Input
                  id="login"
                  className="form-control"
                  type="text"
                  placeholder="Login"
                  value={login || ""}
                  onChange={(e) => setLogin(trataUsename(e.target.value))}
                  maxLength={30}
                />
              </div>
            </Col>
            <Col md="4">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email || ""}
                  onChange={(e) => setEmail(formatEmail(e.target.value))}
                  maxLength={150}
                />
              </div>
            </Col>
            <Col md="4">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  defaultValue={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      id="telefone"
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <Label htmlFor="tipo" className="form-label">
                Tipo
              </Label>
              <Input
                type="select"
                id="tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                className="form-control"
              >
                <option value="usuario">Usuário</option>
                <option value="admin">Administrador</option>
                <option value="super-admin">Super Administrador</option>
              </Input>
            </Col>
            <Col md="6">
              <Label htmlFor="filial" className="form-label">
                Filial <span className="text-danger">*</span>
              </Label>
              <Select
                value={filial}
                onChange={(sortBy) => {
                  setFilial(sortBy);
                }}
                options={optionsFiliais}
                id="filial"
                placeholder={loading ? "Carregando..." : "Selecione a filial"}
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="10">
              <Label htmlFor="funcoes" className="form-label">
                Funções
              </Label>
              <Select
                value={funcao}
                onChange={(sortBy) => {
                  setFuncao(sortBy);
                }}
                options={optionsFuncoes}
                id="funcoes"
                placeholder={loading ? "Carregando..." : "Selecione a função"}
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="2" className="pt-2">
              <button
                className="btn btn-sm btn-success mt-4"
                onClick={() => {
                  if (funcao?.value) {
                    setFuncoesSelecionadas([...funcoesSelecionadas, funcao]);
                    setFuncao({});
                  }
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Função</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {funcoesSelecionadas.map((funcao, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {funcao.label}
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            const newFuncoes = funcoesSelecionadas.filter(
                              (funcao, i) => i !== index
                            );
                            setFuncoesSelecionadas(newFuncoes);
                          }}
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => editar(usuario)}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
